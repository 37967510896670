import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getDisplayName from 'react-display-name';
import { AllStateCtx } from './runtime-context';
import { TIMESTAMP_REGISTRAR_KEY } from '@wix/communities-forum-client-commons';
import { isEditor, isPreview } from '../../store/basic-params/basic-params-selectors';
import { useContext } from '../../api-providers/use-context-selector';

const allStateConnect = (WrappedComponent) => {
  const ConnectedBaseComponent = (props) => {
    const context = useContext(AllStateCtx);
    return <WrappedComponent {...props} _runtimeProps={context} />;
  };

  hoistNonReactStatics(ConnectedBaseComponent, WrappedComponent);

  return ConnectedBaseComponent;
};

export default (mapRuntimeToProps, reducersToListenTo = [], alwaysRender = false) =>
  (WrappedComponent) => {
    class BaseComponent extends Component {
      static displayName = `connect(${getDisplayName(WrappedComponent)})`;

      constructor(props) {
        super(props);
        this.reducerTimestamps = {};
        this.isEditor = isEditor(props._runtimeProps.state);
        this.isPreview = isPreview(props._runtimeProps.state);
      }

      /* TODO: if host is accessed we might need to check for host props diff */
      shouldComponentUpdate(nextProps) {
        if (this.isEditor || this.isPreview || reducersToListenTo.length === 0 || alwaysRender) {
          return true;
        }
        for (const r of reducersToListenTo) {
          if (
            this.reducerTimestamps[r] !== nextProps._runtimeProps.state[TIMESTAMP_REGISTRAR_KEY][r]
          ) {
            return true;
          }
        }

        for (const propKey in this.props) {
          if (propKey === '_runtimeProps') {
            continue;
          }
          if (propKey === 'fastForm') {
            return true;
          }
          if (this.props[propKey] !== nextProps[propKey]) {
            return true;
          }
        }

        if (Object.keys(this.props).length !== Object.keys(nextProps).length) {
          return true;
        }

        return false;
      }

      saveReducerUpdateTimes = () => {
        for (const r of reducersToListenTo) {
          this.reducerTimestamps[r] = this.props._runtimeProps.state[TIMESTAMP_REGISTRAR_KEY][r];
        }
      };
      render() {
        const { _runtimeProps, ...rest } = this.props;
        this.saveReducerUpdateTimes();
        const props = mapRuntimeToProps(
          _runtimeProps.state,
          this.props,
          _runtimeProps.actions,
          _runtimeProps.host,
          _runtimeProps.whenWorkerInitialized,
        );
        return <WrappedComponent {...rest} {...props} />;
      }
    }
    hoistNonReactStatics(BaseComponent, WrappedComponent);
    return allStateConnect(BaseComponent);
  };
