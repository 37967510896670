import { SET_INSTALLED_APPS } from '../actions/vertical-embed-actions';

export const verticalEmbed = (state = { installedApps: [] }, action = {}) => {
  switch (action.type) {
    case SET_INSTALLED_APPS:
      return { ...state, installedApps: action.payload };
    default:
      return state;
  }
};
