export const CREATE_POST = 'create-post';
export const UPDATE_POST = 'update-post';
export const DELETE_POST = 'delete-post';
export const DELETE_CATEGORY = 'delete-category';
export const FOLLOW_CATEGORY = 'follow-category';
export const FOLLOW_POST = 'follow-post';
export const UNFOLLOW_CATEGORY = 'unfollow-category';
export const SSR_SUCCESS = 'ssr-success';
export const PROVISION_SUCCESS = 'provision-success';
export const CREATE_NEW_POST = 'create-new-post';
export const CREATE_NEW_QUESTION = 'create-new-question';
export const GO_TO_MY_POSTS = 'go-to-my-posts';
export const NAVIGATE = 'navigate';
export const REACT_TO_POST = 'react-to-post';
export const FOLLOW_MEMBER = 'follow-member';
