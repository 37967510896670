import createAction from '../../common/services/create-action';
import { URI as uri } from '@wix/communities-forum-client-commons';

export const FETCH_SIMILAR_POSTS_REQUEST = 'similarPosts/FETCH_REQUEST';
export const FETCH_SIMILAR_POSTS_SUCCESS = 'similarPosts/FETCH_SUCCESS';
export const FETCH_SIMILAR_POSTS_FAILURE = 'similarPosts/FETCH_FAILURE';

export const fetchSimilarPostsRequest = createAction(FETCH_SIMILAR_POSTS_REQUEST);
export const fetchSimilarPostsSuccess = createAction(FETCH_SIMILAR_POSTS_SUCCESS);
export const fetchSimilarPostsFailure = createAction(FETCH_SIMILAR_POSTS_FAILURE);

export const fetchSimilarPosts =
  (query) =>
  (dispatch, getState, { request }) => {
    dispatch(fetchSimilarPostsRequest());

    const path = uri(`/search/suggest`).query({ q: query, limit: 5 });
    const promise = request(path);

    return promise
      .then(
        (results) => dispatch(fetchSimilarPostsSuccess(results.posts)),
        () => dispatch(fetchSimilarPostsFailure()),
      )
      .then(() => promise);
  };
