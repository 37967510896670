import { AppDispatch } from '../reducers/app-dispatch';
import { userEventsPostCaptchaResolved, userEventsPostCaptchaShown } from '../actions/user-events';

export const getCaptchaToken = async (dispatch: AppDispatch, wixCodeApi: any) => {
  dispatch(userEventsPostCaptchaShown());
  const token = await wixCodeApi.authentication.openCaptchaChallenge();
  dispatch(userEventsPostCaptchaResolved(!!token));

  return token;
};
