import { MembersInternalState } from '../members/members-types';
import {
  FOLLOW_MEMBER_FAILURE,
  FOLLOW_MEMBER_REQUEST,
  FOLLOW_MEMBER_SUCCESS,
  MemberFollowActions,
  UNFOLLOW_MEMBER_FAILURE,
  UNFOLLOW_MEMBER_REQUEST,
  UNFOLLOW_MEMBER_SUCCESS,
} from './member-follow-actions';

export function memberFollowReducer(
  state: MembersInternalState,
  action?: MemberFollowActions,
): MembersInternalState | {} {
  switch (action?.type) {
    case FOLLOW_MEMBER_REQUEST: {
      return {
        ...state,
        followedMembers: [
          ...state.followedMembers,
          { id: action.payload.memberId, status: 'PENDING_FOLLOW' },
        ],
      };
    }
    case FOLLOW_MEMBER_SUCCESS: {
      return {
        ...state,
        followedMembers: state.followedMembers.map((member) => {
          if (member.id === action.payload.memberId) {
            return { ...member, status: 'FOLLOWED' };
          } else {
            return member;
          }
        }),
      };
    }
    case FOLLOW_MEMBER_FAILURE: {
      return {
        ...state,
        followedMembers: state.followedMembers.filter(
          (member) => member.id !== action.payload.memberId,
        ),
      };
    }
    case UNFOLLOW_MEMBER_REQUEST: {
      return {
        ...state,
        followedMembers: state.followedMembers.map((member) => {
          if (member.id === action.payload.memberId) {
            return { ...member, status: 'PENDING_UNFOLLOW' };
          } else {
            return member;
          }
        }),
      };
    }
    case UNFOLLOW_MEMBER_SUCCESS: {
      return {
        ...state,
        followedMembers: state.followedMembers.filter(
          (member) => member.id !== action.payload.memberId,
        ),
      };
    }
    case UNFOLLOW_MEMBER_FAILURE: {
      return {
        ...state,
        followedMembers: state.followedMembers.map((member) => {
          if (member.id === action.payload.memberId) {
            return { ...member, status: 'FOLLOWED' };
          } else {
            return member;
          }
        }),
      };
    }
    default:
      return state || {};
  }
}
