import { combineReducers } from 'redux';
import { openedModals } from './opened-modals';
import { resolvedModalList } from './resolved-modal-list';
import { isLoading } from './is-loading';

export const modal = (...args) =>
  combineReducers({
    openedModals: openedModals(...args),
    resolvedModalList,
    isLoading,
  });
