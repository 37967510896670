import { get } from 'lodash';
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_COMPACT,
  LAYOUT_TYPE_PATH,
  LAYOUT_TYPE_MAIN_PAGE_PATH,
  LAYOUT_TYPE_PINBOARD,
  LAYOUT_TYPE_FEED,
} from '@wix/communities-forum-client-commons';
import { PostBreakpoints } from '../components/responsive-listener';

export const getLayoutType = (state, style) => {
  const layout = get(style, LAYOUT_TYPE_PATH, LAYOUT_TYPE_CLASSIC);
  return layout === LAYOUT_TYPE_COMPACT ? LAYOUT_TYPE_CLASSIC : layout;
};

export const getMainPageLayoutType = (state, style) =>
  get(style, LAYOUT_TYPE_MAIN_PAGE_PATH, getLayoutType(state, style));

export const isInfiniteScrollLayoutType = (state, style) => {
  const layoutType = getLayoutType(state, style);
  return layoutType === LAYOUT_TYPE_PINBOARD || isFeedLayoutType(state, style);
};

export const isFeedLayoutType = (state, style) => {
  const layoutType = getLayoutType(state, style);
  return layoutType === LAYOUT_TYPE_FEED;
};

export const getForumContainerBreakpointValue = (containerWidth = 980) => {
  if (containerWidth < PostBreakpoints.xs) {
    return PostBreakpoints.xs;
  }

  if (containerWidth < PostBreakpoints.sm) {
    return PostBreakpoints.sm;
  }

  if (containerWidth < PostBreakpoints.md) {
    return PostBreakpoints.md;
  }
};
