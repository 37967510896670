export function getStaticsVersionFromUrl(ownStaticUrl = '') {
  const versionMatch = ownStaticUrl.match(/\/(\d+\.\d+\.\d+)\//);
  const version = versionMatch
    ? versionMatch[1]
    : ownStaticUrl.includes('localhost')
    ? 'localhost'
    : 'unknown';

  return `$frm@${version}`;
}
