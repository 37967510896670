import { loadModalStart, loadModalStop, OPEN_MODAL } from './modal-actions';

export const createModalOpenedMiddleware =
  ({ modalDataLoaderByType }) =>
  (store) => {
    return (nextMiddleware) => (action) => {
      const next = () => nextMiddleware(action);

      if (action.type === OPEN_MODAL) {
        const {
          payload: { type, props },
        } = action;

        const onShow = modalDataLoaderByType[type];
        if (onShow && props) {
          const action = onShow(props);
          store.dispatch(loadModalStart(type));
          const promise = store.dispatch(action);
          if (promise && promise.then) {
            promise.then(() => store.dispatch(loadModalStop(type)));
          } else {
            store.dispatch(loadModalStop(type));
          }
        }
      }

      return next();
    };
  };
