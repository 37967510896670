import { SET_CUSTOM_ROUTES } from './custom-routes-actions';

export const customRoutes = (state = {}, action) => {
  switch (action.type) {
    case SET_CUSTOM_ROUTES:
      return action.payload;
    default:
      return state;
  }
};
