import createAction from '../../services/create-action';

export const RESOLVE_PROMISIFIED_ACTION = 'RESOLVE_PROMISIFIED_ACTION';
export const resolvePromisifiedAction = createAction(
  RESOLVE_PROMISIFIED_ACTION,
  (isResolved, correlationId, payload) => ({
    isResolved,
    correlationId,
    payload,
  }),
);
