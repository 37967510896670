import createAction from '../../common/services/create-action';
import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { markPostsAsSeen } from '../containers/user-activity';

export const FETCH_RELATED_POSTS_REQUEST = 'related-posts/FETCH_REQUEST';
export const FETCH_RELATED_POSTS_SUCCESS = 'related-posts/FETCH_SUCCESS';
export const FETCH_RELATED_POSTS_FAILURE = 'related-posts/FETCH_FAILURE';

export const fetchRelatedPostsRequest = createAction(FETCH_RELATED_POSTS_REQUEST);
export const fetchRelatedPostsSuccess = createAction(FETCH_RELATED_POSTS_SUCCESS);
export const fetchRelatedPostsFailure = createAction(FETCH_RELATED_POSTS_FAILURE);

export function fetchRelatedPosts(postSlug) {
  return (dispatch, getState, { request }) => {
    const state = getState();
    dispatch(fetchRelatedPostsRequest());

    const promise = request(`/search/related/${postSlug}`);

    if (!isSSR(state)) {
      promise.then((response) => {
        if (response.posts.length > 0) {
          dispatch(markPostsAsSeen(response.posts.map((p) => p._id)));
        }
      });
    }

    return promise
      .then(
        (results) => dispatch(fetchRelatedPostsSuccess(results.posts)),
        dispatch(fetchRelatedPostsFailure()),
      )
      .then(() => promise);
  };
}
