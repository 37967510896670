import {
  SET_COMMUNITIES_CONTEXT,
  SET_IS_MEMBERS_CHAT_ENABLED,
} from './communities-context-actions';

export function communitiesContext(state = {}, action) {
  switch (action.type) {
    case SET_COMMUNITIES_CONTEXT:
      return action.payload;
    case SET_IS_MEMBERS_CHAT_ENABLED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
