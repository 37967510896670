import {
  SANTA_MEMBERS_APP_ID,
  MEMBER_INFO_APP_ID,
  MEMBER_INFO_SECTION_ID,
  PAID_PLANS_APP_ID,
  PAID_PLANS_SECTION_ID,
  MEMBER_INFO_APP_ID_V2,
  MEMBER_INFO_SECTION_ID_V2,
} from '@wix/communities-forum-universal/dist/src/constants/appsConfig';
import { CHAT_APP_DEF_ID } from '../../../app/constants/applications';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';
export const SET_IS_MEMBERS_CHAT_ENABLED = 'communitiesContext/SET_IS_MEMBERS_CHAT_ENABLED';

export const isMaInstalled = async (wixCodeApi) => {
  const [oldMembersAppIsInstalled, newMembersAppIsInstalled] = await Promise.all([
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MEMBER_INFO_APP_ID,
      sectionId: MEMBER_INFO_SECTION_ID,
    }),
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MEMBER_INFO_APP_ID_V2,
      sectionId: MEMBER_INFO_SECTION_ID_V2,
    }),
  ]);

  return oldMembersAppIsInstalled || newMembersAppIsInstalled;
};

export const isPaidPlansInstalled = (wixCodeApi) =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
  );

export const isMembersChatEnabled = (wixCodeApi) =>
  wixCodeApi.site
    .getPublicAPI(CHAT_APP_DEF_ID)
    .then((api) => api.isMembersChatEnabled())
    .catch(() => false);

export const initIsMembersChatEnabled =
  () =>
  (dispatch, getState, { wixCodeApi }) => {
    isMembersChatEnabled(wixCodeApi).then((isMAChatEnabled) =>
      dispatch({
        type: SET_IS_MEMBERS_CHAT_ENABLED,
        payload: {
          [CHAT_APP_DEF_ID]: {
            isMembersChatEnabled: isMAChatEnabled,
          },
        },
      }),
    );
  };

export const setCommunitiesContext =
  () =>
  (dispatch, getState, { wixCodeApi }) => {
    Promise.all([isMaInstalled(wixCodeApi), isPaidPlansInstalled(wixCodeApi)]).then(
      ([isInstalledA, isInstalledB]) => {
        dispatch({
          type: SET_COMMUNITIES_CONTEXT,
          payload: {
            [SANTA_MEMBERS_APP_ID]: {
              isInstalled: isInstalledA,
            },
            [PAID_PLANS_APP_ID]: {
              isInstalled: isInstalledB,
            },
          },
        });
      },
    );
  };
