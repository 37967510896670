const DUPLEXER_EVENTS = {
  NEW_POST: 'new-post',
  UPDATE_POST_COUNTERS: 'update-post-counters',
  UPDATE_CATEGORY_COUNTERS: 'update-category-counters',
};

const DUPLEXER_CHANNELS = {
  FORUM: 'forum',
  CATEGORY: 'category',
  POST: 'post',
};

module.exports = { DUPLEXER_EVENTS, DUPLEXER_CHANNELS };
