import { isString, isNumber } from 'lodash';

export function actionPromiseCache(
  fn,
  { cacheKeys, cacheKeyBuilder, cacheTime = 500, ignoreResolve = false } = {},
) {
  const cache = {};

  return (...args) =>
    (...secondArgs) => {
      const k = getCacheKey(args, cacheKeys, cacheKeyBuilder);
      const promise = cache[k];
      if (promise && (ignoreResolve || promise.isPending())) {
        return promise;
      }

      setTimeout(() => {
        delete cache[k];
      }, cacheTime);

      cache[k] = makeQuerablePromise(fn(...args)(...secondArgs));
      return cache[k];
    };
}

function getCacheKey(args, cacheKeysIndex, cacheKeyBuilder) {
  if (cacheKeysIndex) {
    return cacheKeysIndex.map((k) => args[k]).join('');
  }

  if (cacheKeyBuilder) {
    return cacheKeyBuilder(...args);
  }

  return args.reduce(
    (result, a) => (result ? result : isString(a) || isNumber(a) ? a : null),
    null,
  );
}

function makeQuerablePromise(promise) {
  // Don't modify any promise that has been already modified.
  if (promise.isResolved) {
    return promise;
  }

  // Set initial state
  let isPending = true;
  let isRejected = false;
  let isFulfilled = false;

  // Observe the promise, saving the fulfillment in a closure scope.
  const result = promise.then(
    (v) => {
      isFulfilled = true;
      isPending = false;
      return v;
    },
    (e) => {
      isRejected = true;
      isPending = false;
      throw e;
    },
  );

  result.isFulfilled = function () {
    return isFulfilled;
  };
  result.isPending = function () {
    return isPending;
  };
  result.isRejected = function () {
    return isRejected;
  };
  return result;
}
