import { debounce, get } from 'lodash';
import { batchActions } from 'redux-batched-actions';

/* 
This middleware enables debouncing actions and dispatching them as batches
so you can either debounce a single action or multiple ones (batches).
It enables powerful optimizations, as actions that are not needed to be instant can be delayed and
grouped.


configPerBatch: {[batchName: string]: {
  invokeAsBatch: boolean (if true will skip other middleware, but invoke only single store update),
  uniqueOnly: boolean (if true, won't add multiple instances of same action to the batch, only keep latest),
  debounce: number, options: {trailing: boolean, leading: boolean, maxWait: number}
}} 

config can also be passed directly in action, see examples below.


Examples:

// a simple debounce, that also specifies config
{
  type: OEMBED,
  meta: { throttle: { batch: 'oembed', config: { debounce: 1 } } }
}

// this is an action that belongs to batch 'sockets' and will use either config passed when initializing middleware or default one
dispatch({
  type: ADD_TYPING_USER,
  payload: user,
  meta: {
    throttle: {
      batch: 'sockets',
    },
  },
});

// this action is not only debounced but it has a "hook" beforeCommit that will be invoked before action itsef is dispatched
// after debounce is over. This hook also invokes a meta action that removes another action from being debounced
{
  type: ANY,
  meta: {
    throttle: {
      batch: 'remove-typing-users',
      config: { debounce: REMOVE_TYPING_USER_TIMEOUT, uniqueOnly: true },
      beforeCommit: () => {
        // remove the ADD_TYPING_USER from the bach of debounced socket actions
        dispatch({
          type: REMOVE_ACTION,
          meta: {
            throttle: {
              batch: 'sockets',
              filter: a =>
                a.type !== ADD_TYPING_USER &&
                a.payload.user.siteMemberId !== payload.user.siteMemberId,
            },
          },
        });
      },
    },
  }
}
*/

const DEFAULT_CONFIG = {
  debounce: 500,
  invokeAsBatch: true,
  options: { leading: false, trailing: true, maxWait: 3000 },
};
export const createThrottleMiddleware =
  (configPerBatch = {}) =>
  (store) => {
    const throttledNextPerBatch = {};
    const actionsPerBatch = {};

    const getBatchName = (action) => get(action, 'meta.throttle.batch');

    const createNextBatched = (next) => {
      const addToBatch = (action, uniqueOnly = false) => {
        const batch = getBatchName(action);
        if (!actionsPerBatch[batch]) {
          actionsPerBatch[batch] = [];
        }

        if (uniqueOnly) {
          actionsPerBatch[batch].splice(
            actionsPerBatch[batch].findIndex((a) => a.type === action.type),
            1,
            action,
          );
        } else {
          actionsPerBatch[batch].push(action);
        }
      };

      const createInvokeBatch = (batch) => () => {
        actionsPerBatch[batch].forEach(
          (a) => a.meta.throttle.beforeCommit && a.meta.throttle.beforeCommit(),
        );
        store.dispatch(batchActions(actionsPerBatch[batch]));
        actionsPerBatch[batch] = [];
      };

      const createInvokeBatchIndividually = (batch) => () => {
        store.dispatch(batchActions);
        for (const action of actionsPerBatch[batch]) {
          action.meta.throttle.beforeCommit && action.meta.throttle.beforeCommit();
          next(action);
        }
        actionsPerBatch[batch] = [];
      };

      return {
        next: (action) => {
          const batch = getBatchName(action);
          const config = configPerBatch[batch] || action.meta.throttle.config || DEFAULT_CONFIG;
          if (!throttledNextPerBatch[batch]) {
            throttledNextPerBatch[batch] = debounce(
              config.invokeAsBatch
                ? createInvokeBatch(batch)
                : createInvokeBatchIndividually(batch),
              config.debounce,
              config.options,
            );
          }

          addToBatch(action, config.uniqueOnly);
          return throttledNextPerBatch[batch]();
        },
        flush: (action) => {
          // invoke batch now
          const batch = getBatchName(action);
          if (throttledNextPerBatch[batch]) {
            throttledNextPerBatch[batch].flush();
            actionsPerBatch[batch] = [];
          }
        },
        cancel: (action) => {
          // cancel invocation of the batch
          const batch = getBatchName(action);
          if (throttledNextPerBatch[batch]) {
            throttledNextPerBatch[batch].cancel();
            actionsPerBatch[batch] = [];
          }
        },
        removeAction: (action) => {
          // remove a specific actions(-s) from the batch
          const batch = getBatchName(action);
          if (actionsPerBatch[batch]) {
            actionsPerBatch[batch] = actionsPerBatch[batch].filter((a) =>
              action.meta.throttle.filter(a),
            );
          }
        },
      };
    };

    return (next) => {
      const nextBatched = createNextBatched(next);

      return (action) => {
        if (action.type === FLUSH) {
          return nextBatched.flush(action);
        }

        if (action.type === CANCEL) {
          return nextBatched.cancel(action);
        }

        if (action.type === REMOVE_ACTION) {
          return nextBatched.cancel(action);
        }
        if (action.meta && action.meta.throttle) {
          return nextBatched.next(action);
        }

        return next(action);
      };
    };
  };

// These are actions that allow to interface with state of this middleware
export const FLUSH = 'throttled/FLUSH';
export const CANCEL = 'throttled/CANCEL';
export const REMOVE_ACTION = 'throttled/REMOVE_ACTION';
