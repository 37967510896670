import { subscribeToCategory } from '../actions/subscribe-to-category';
import { subscribeToPost } from '../actions/subscribe-to-post';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getCategory } from '../../common/selectors/categories-selectors';
import { getPost } from '../selectors/post-selectors';
import {
  CREATE_NEW_POST,
  FOLLOW_CATEGORY,
  FOLLOW_POST,
  CREATE_NEW_QUESTION,
  GO_TO_MY_POSTS,
  REACT_TO_POST,
  FOLLOW_MEMBER,
} from '../constants/interactions';
import { getSavedAction } from '../../common/store/saved-action/saved-action-selector';
import { reactToPost } from '../containers/post-reactions/post-reactions-actions';
import { followMember } from '../../common/store/member-follow/member-follow-actions';

export function invokeSavedAction(store) {
  const state = store.getState();
  const { action, args } = getSavedAction(state);
  switch (action) {
    case FOLLOW_CATEGORY:
      const category = getCategory(state, ...args);
      if (!category.isSubscribed) {
        store.dispatch(subscribeToCategory(...args));
      }
      return;
    case FOLLOW_POST:
      const post = getPost(state, ...args);
      if (!post.isSubscribed) {
        store.dispatch(subscribeToPost(...args));
      }
      return;
    case CREATE_NEW_POST:
      store.dispatch(navigateWithinForum(...args));
      return;
    case CREATE_NEW_QUESTION:
      store.dispatch(navigateWithinForum(...args));
      return;
    case GO_TO_MY_POSTS:
      store.dispatch(navigateWithinForum(args));
      return;
    case REACT_TO_POST:
      store.dispatch(reactToPost(...args));
      return;
    case FOLLOW_MEMBER:
      store.dispatch(followMember(...args));
      return;
    default:
      return;
  }
}
