export const getIsLoading = (state) => state.isLoading;

export const getIsEntityLoading = (state, entity, id = '') =>
  Boolean((getIsLoading(state)[entity] || {})[id]);

export const getIsCategoryLoading = createIsLoadingSelector('category');
export const getIsPostLoading = createIsLoadingSelector('post');
export const getIsPostsLoading = createIsLoadingSelector('posts');
export const getIsSearchLoading = createIsLoadingSelector('search');
export const getIsFooterPostsLoading = createIsLoadingSelector('footer-posts');
export const getIsSimilarPostsLoading = createIsLoadingSelector('similar-posts');
export const getIsExternalLinksLoading = createIsLoadingSelector('external-links');

function createIsLoadingSelector(entity) {
  return (state, id) => getIsEntityLoading(state, entity, id);
}
