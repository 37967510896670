import { fetchCommentsClientTranslations } from '@wix/comments-ooi-client/translations';
import { AppThunk } from '../../../app/types/store-types';
import createAction from '../../services/create-action';

export const FETCH_TRANSLATIONS_REQUEST = 'comments/FETCH_TRANSLATIONS_REQUEST' as const;
export const FETCH_TRANSLATIONS_SUCCESS = 'comments/FETCH_TRANSLATIONS_SUCCESS' as const;
export const FETCH_TRANSLATIONS_FAILURE = 'comments/FETCH_TRANSLATIONS_FAILURE' as const;

export const fetchTranslationsRequest = createAction(FETCH_TRANSLATIONS_REQUEST);
export const fetchTranslationsSuccess = createAction(FETCH_TRANSLATIONS_SUCCESS);
export const fetchTranslationsFailure = createAction(FETCH_TRANSLATIONS_FAILURE);

export function fetchRreactionsTranslations(language: string): AppThunk {
  return (dispatch, _getState, { httpClient }) => {
    dispatch(fetchTranslationsRequest());

    const loadTranslations = () => fetchCommentsClientTranslations(httpClient, language);

    return loadTranslations().then(
      (body) => dispatch(fetchTranslationsSuccess(body.data)),
      () => dispatch(fetchTranslationsFailure()),
    );
  };
}
