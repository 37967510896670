export function unreachable(param: never) {
  return new Error(`Unreachable case with value ${JSON.stringify(param)}`);
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export type VoidReturn<T extends Function> = T extends (payload: infer A) => any
  ? (payload: A) => void
  : never;

export type RequiredKeys<T, K extends keyof T> = Exclude<T, K> & {
  [key in K]-?: NonNullable<T[key]>;
};

export function assertFields<T extends {}, K extends keyof T>(
  obj: T,
  fields: K[],
  objectName: string,
): asserts obj is RequiredKeys<T, K> {
  const notFoundFields = fields.filter((key) => obj[key] === undefined || obj[key] === null);
  if (notFoundFields.length) {
    throw new Error(
      `${objectName}: Expected fields ${fields.join(
        ', ',
      )}, but these fields where missing: ${notFoundFields.join(', ')}`,
    );
  }
}

export const isDefined = <T>(item: T | null | undefined): item is T =>
  item !== null && item !== undefined;
