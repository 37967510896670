import { dsl } from '@wix/yoshi-serverless/wrap';

export const followMember = dsl({
          functionName: 'followMember',
          fileName: 'api/members/follow-member.api',
        });

export const unfollowMember = dsl({
          functionName: 'unfollowMember',
          fileName: 'api/members/follow-member.api',
        });