import { OFFLINE_MIGRATION_TRIGGER } from '../actions/offline-migration-actions';

const initialState = {
  wasOfflineMigrationTriggered: false,
};

export function offlineMigration(state = initialState, action) {
  if (action.type === OFFLINE_MIGRATION_TRIGGER) {
    return { wasOfflineMigrationTriggered: true };
  } else {
    return state;
  }
}
