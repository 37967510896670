import { FETCH_RELATED_POSTS_SUCCESS } from '../actions/fetch-related-posts';
import { DELETE_CATEGORY_SUCCESS } from '../actions/delete-category';

export function relatedPosts(state = [], { type, payload } = {}) {
  switch (type) {
    case FETCH_RELATED_POSTS_SUCCESS:
      return payload;
    case DELETE_CATEGORY_SUCCESS:
      return state.filter((p) => p.categoryId !== payload);
    default:
      return state;
  }
}
