import { createAction } from '@reduxjs/toolkit';
import {
  followMember as followMemberApi,
  unfollowMember as unfollowMemberApi,
} from '../../../api/members/follow-member.api';
import { ERROR_GENERIC } from '../../../app/components/messages/message-types';
import { AppThunk } from '../../../app/types/store-types';
import { getCurrentUser } from '../members/members-selectors';
import { showMessage } from '../../messages/framework/store/message-actions';

export const FOLLOW_MEMBER_REQUEST = 'FOLLOW_MEMBER_REQUEST' as const;
export const FOLLOW_MEMBER_FAILURE = 'FOLLOW_MEMBER_FAILURE' as const;
export const FOLLOW_MEMBER_SUCCESS = 'FOLLOW_MEMBER_SUCCESS' as const;

export const UNFOLLOW_MEMBER_REQUEST = 'UNFOLLOW_MEMBER_REQUEST' as const;
export const UNFOLLOW_MEMBER_FAILURE = 'UNFOLLOW_MEMBER_FAILURE' as const;
export const UNFOLLOW_MEMBER_SUCCESS = 'UNFOLLOW_MEMBER_SUCCESS' as const;

export type MemberFollowActions =
  | ReturnType<typeof followMemberRequest>
  | ReturnType<typeof followMemberFailure>
  | ReturnType<typeof followMemberSuccess>
  | ReturnType<typeof unfollowMemberRequest>
  | ReturnType<typeof unfollowMemberFailure>
  | ReturnType<typeof unfollowMemberSuccess>;

type MemberPayload = { memberId: string };

export const followMemberRequest = createAction<MemberPayload>(FOLLOW_MEMBER_REQUEST);
export const followMemberFailure = createAction<MemberPayload & { error: any }>(
  FOLLOW_MEMBER_FAILURE,
);
export const followMemberSuccess = createAction<MemberPayload>(FOLLOW_MEMBER_SUCCESS);

export const unfollowMemberRequest = createAction<MemberPayload>(UNFOLLOW_MEMBER_REQUEST);
export const unfollowMemberFailure = createAction<MemberPayload & { error: any }>(
  UNFOLLOW_MEMBER_FAILURE,
);
export const unfollowMemberSuccess = createAction<MemberPayload>(UNFOLLOW_MEMBER_SUCCESS);

export const followMember =
  ({ memberId }: { memberId: string }): AppThunk =>
  async (dispatch, getState, { request }) => {
    const state = getState();
    const currentUser = getCurrentUser(state);

    if (currentUser?.siteMemberId === memberId) {
      return;
    }

    dispatch(followMemberRequest({ memberId }));

    const promise = request.httpClient.request(followMemberApi({ memberId }));

    return promise
      .then(() => dispatch(followMemberSuccess({ memberId })))
      .catch((err) => {
        dispatch(followMemberFailure({ memberId, error: err }));
        dispatch(showMessage(ERROR_GENERIC));
      })
      .then(() => promise)
      .then(({ data }) => data);
  };

export const unfollowMember =
  ({ memberId }: { memberId: string }): AppThunk =>
  async (dispatch, getState, { request }) => {
    dispatch(unfollowMemberRequest({ memberId }));

    const promise = request.httpClient.request(unfollowMemberApi({ memberId }));

    return promise
      .then(() => dispatch(unfollowMemberSuccess({ memberId })))
      .catch((err) => {
        dispatch(unfollowMemberFailure({ memberId, error: err }));
        dispatch(showMessage(ERROR_GENERIC));
      })
      .then(() => promise)
      .then(({ data }) => data);
  };
