import Wix from './wix-sdk-polyfill';
import { setComponentData } from '../../common/store/component-data/component-data-actions';
import { setStyleParams } from '../../common/store/style-params/style-params-actions';
import { getStyleParams } from '../../common/store/style-params/style-params-selectors';
import {
  getIsForumStructureRegular,
  getPostFeedCommentsCount,
  getPostsLayoutType,
} from '../selectors/app-settings-selectors';
import { getSettingsEvent } from '@wix/communities-forum-client-commons';

const onSettingsChange = (store, styleParams, doRouting) => {
  const state = store.getState();
  const pairsToMatch = [
    [
      getIsForumStructureRegular({
        styleParams: getStyleParams(state),
      }),
      getIsForumStructureRegular({
        styleParams,
      }),
    ],
    [
      getPostFeedCommentsCount(
        {},
        {
          styleParams: getStyleParams(state),
        },
      ),
      getPostFeedCommentsCount(
        {},
        {
          styleParams,
        },
      ),
    ],
    [
      getPostsLayoutType({
        styleParams: getStyleParams(state),
      }),
      getPostsLayoutType({
        styleParams,
      }),
    ],
  ];

  if (pairsToMatch.some(([prev, next]) => prev !== next)) {
    setTimeout(() => doRouting());
  }
};

export default function listenToSettingsChange(store, doRouting) {
  Wix.addEventListener(Wix.Events.PUBLIC_DATA_CHANGED, (data) =>
    store.dispatch(setComponentData(data)),
  );
  Wix.addEventListener(Wix.Events.SETTINGS_UPDATED, (data) => store.dispatch(data));

  Wix.addEventListener(Wix.Events.STYLE_PARAMS_CHANGE, (styleParams) => {
    onSettingsChange(store, styleParams, doRouting);
    store.dispatch(setStyleParams(styleParams));
  });
}

export function applySettingsChange(store, doRouting) {
  return ({ publicData, style }) => {
    // public data
    store.dispatch(setComponentData(publicData.COMPONENT));

    // settings event
    const settingsEvent = getSettingsEvent(publicData);
    if (settingsEvent) {
      store.dispatch(settingsEvent);
    }

    // style params
    if (doRouting) {
      onSettingsChange(store, style.styleParams, doRouting);
    }
    store.dispatch(setStyleParams(style.styleParams));
  };
}
