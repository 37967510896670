import { UNFOLLOW_CATEGORY } from '../../../constants/interactions';
import { CATEGORY_UNSUBSCRIBED } from '../../messages/message-types';
import { unsubscribe } from '../../../actions/unsubscribe';

export const unfollowCategoryModalResolver =
  (categoryId, { showMessage }) =>
  (dispatch, getState, { fedopsLogger }) => {
    fedopsLogger.interactionStarted(UNFOLLOW_CATEGORY);
    return dispatch(unsubscribe({ _id: categoryId, type: 'category' })).then(() => {
      fedopsLogger.interactionEnded(UNFOLLOW_CATEGORY);
      return showMessage(CATEGORY_UNSUBSCRIBED);
    });
  };
