import { RootState } from '../types/store-types';
import { shouldShowCaptcha } from './utils/comments-utils';
import {
  userEventsCommentCaptchaResolved,
  userEventsCommentCaptchaShown,
} from '../actions/user-events';
import { getFilteredWords, getSpamWords } from '../selectors/forum-data-selectors';
import { setPostSeoTags } from '../actions/set-post-seo-tags';
import { Router } from '../../common/router';
import { getSectionUrl, getUrl } from '../../common/store/location/location-selectors';
import { ROUTE_POST } from '@wix/communities-forum-client-commons';
import {
  createExternalHook,
  isExternalHookReject,
  isExternalHookResolve,
} from '../../common/services/external-hooks';
import { WixCommentsApi } from '../types';
import type { Node } from '@wix/ricos';
import { Node_Type, RichContent, modify } from '@wix/ricos';

type PostPagePaginationParamsStore = {
  value: any;
  update: (newValue: any) => void;
};

export const configureCommentsApi = ({
  wixCommentsApi,
  wixCodeApi,
  store,
  postPagePaginationParamsStore,
  router,
  onBeforeCommentCreateHook,
  onBeforeCommentUpdateHook,
}: {
  wixCommentsApi: WixCommentsApi;
  wixCodeApi: any;
  store: RootState;
  postPagePaginationParamsStore: PostPagePaginationParamsStore;
  router: Router;
  onBeforeCommentCreateHook: ReturnType<typeof createExternalHook>;
  onBeforeCommentUpdateHook: ReturnType<typeof createExternalHook>;
}) => {
  wixCommentsApi.watch.pagination.onChange(async (paginationState) => {
    const state = store.getState();
    const url = getUrl(state);
    const sectionUrl = getSectionUrl(state);

    // When controller is initialized current router match is not yet persisted in state
    // Thus we need to match current url manually, instead of checking state value
    const { route } = router.test(url.replace(sectionUrl, ''));

    // Pagination should be synced only on post page
    if (route !== ROUTE_POST) {
      return;
    }

    store.dispatch(setPostSeoTags(postPagePaginationParamsStore?.value?.params, wixCommentsApi));
  });

  wixCommentsApi.intercept.onBeforeCrud(async (operation) => {
    const state = store.getState();
    let contentToUse = operation.content;
    if (shouldShowCaptcha(state, contentToUse)) {
      const token = await getCaptchaToken();

      if (!token) {
        return {
          type: 'ERROR',
          message: { type: 'SILENT' },
        };
      }
    }

    try {
      let result: any;
      if (operation.operation === 'CREATE' && onBeforeCommentCreateHook.hasHook()) {
        result = await onBeforeCommentCreateHook.exec({ content: contentToUse });
      }

      if (operation.operation === 'UPDATE' && onBeforeCommentUpdateHook.hasHook()) {
        result = await onBeforeCommentUpdateHook.exec({ content: contentToUse });
      }

      if (result && isExternalHookResolve(result)) {
        contentToUse = result?.payload?.content || contentToUse;
      }
    } catch (hookResponse: any) {
      if (isExternalHookReject(hookResponse) && hookResponse.payload) {
        return {
          type: 'ERROR',
          message: hookResponse.payload,
        };
      }
    }

    const spamWords: string[] = getSpamWords(state);

    if (contentContainsSpamWords(contentToUse, spamWords)) {
      return {
        type: 'ERROR',
        message: {
          type: 'EXTERNAL_TRANSLATION',
          key: 'messages.comment-invalid',
        },
      };
    }

    const filteredWords: string[] = getFilteredWords(state);

    return {
      type: 'OK',
      content: replaceContentWithFilteredWords(contentToUse, filteredWords),
    };
  });

  const getCaptchaToken = async (): Promise<string> => {
    store.dispatch(userEventsCommentCaptchaShown());
    const token = await wixCodeApi.authentication.openCaptchaChallenge();
    store.dispatch(userEventsCommentCaptchaResolved(!!token));

    return token;
  };

  const contentContainsSpamWords = (content: RichContent, spamWords: string[]): boolean => {
    return content.nodes.some(
      (n: Node) =>
        n.type === Node_Type.PARAGRAPH &&
        n.nodes.some(
          (t: Node) =>
            t.type === Node_Type.TEXT && spamWords.some((word) => t.textData?.text.includes(word)),
        ),
    );
  };

  const replaceContentWithFilteredWords = (
    content: RichContent,
    filteredWords: string[],
  ): RichContent => {
    return modify(content)
      .filter((n: Node) => n.type === Node_Type.PARAGRAPH)
      .set((n: Node) => ({
        ...n,
        nodes: n.nodes.map((t: Node) =>
          t.type === Node_Type.TEXT
            ? {
                ...t,
                textData: t.textData
                  ? {
                      ...t.textData,
                      text: t.textData.text
                        .split(/\b/)
                        .map((word: string) => {
                          if (filteredWords.indexOf(word.toLowerCase()) > -1) {
                            return '*'.repeat(word.length);
                          }
                          return word;
                        })
                        .join(''),
                    }
                  : t.textData,
              }
            : t,
        ),
      }));
  };
};
