import { createSelector } from 'reselect';

export const getCurrentUser = (state) => state.currentUser;

export const getCurrentUserSiteMemberId = createSelector(
  [getCurrentUser],
  (currentUser) => currentUser && currentUser.siteMemberId,
);

export const isOwner = createSelector([getCurrentUser], (currentUser) => {
  return currentUser ? currentUser.isOwner : false;
});

export const isBlocked = createSelector([getCurrentUser], (currentUser) =>
  Boolean(currentUser && currentUser.isBlocked),
);

export const getCurrentUserRemainingPosts = createSelector(
  [getCurrentUser],
  (currentUser) => currentUser && currentUser.remainingPosts,
);

export const isAuthenticated = (state) => Boolean(state.currentUser);
