import fetchSearch from './fetch-search';
import createAction from '../../common/services/create-action';

export const SEARCH_CLEAR_RESULTS = 'search/CLEAR_RESULTS';

export const MIN_QUERY_LENGTH = 2;

export const clearSearchResults = createAction(SEARCH_CLEAR_RESULTS);

export default function search({
  query = '',
  sort,
  minQueryLength = MIN_QUERY_LENGTH,
  pagination,
}) {
  return (dispatch) => {
    let promise = Promise.resolve();
    if (query.length >= minQueryLength) {
      promise = dispatch(fetchSearch(query, sort, pagination));
    } else {
      dispatch(clearSearchResults());
    }
    return promise;
  };
}
