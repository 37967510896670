import { SET_ROUTER_MATCH } from '../../common/router';
import * as Routes from '@wix/communities-forum-client-commons';
import {
  saveForumDisplayState,
  POST_LIST,
  CATEGORY_LIST,
  OTHER,
} from '@wix/communities-forum-client-commons';

import { getIsForumStructureRegular, getStyle } from '../selectors/app-settings-selectors';

export default function forumDisplayStateMiddleware() {
  return (store) => (next) => (action) => {
    if (action.type === SET_ROUTER_MATCH) {
      switch (action.payload.route) {
        case Routes.ROUTE_CATEGORY:
          saveForumDisplayState(POST_LIST);
          break;
        case Routes.ROUTE_HOME:
          const state = store.getState();
          const isFeed = !getIsForumStructureRegular(getStyle(state));
          saveForumDisplayState(isFeed ? POST_LIST : CATEGORY_LIST);
          break;
        default:
          saveForumDisplayState(OTHER);
      }
    }
    next(action);
  };
}
