import { subscribe } from './subscribe';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { ALL_CATEGORIES_SUBSCRIBED } from '../components/messages/message-types';
import { getUnsubscribedCategories } from '../../common/selectors/categories-selectors';

export function subscribeToAllCategories() {
  return (dispatch, getState) => {
    const unsubscribedCategories = getUnsubscribedCategories(getState());
    return Promise.all(
      unsubscribedCategories.map(({ _id }) => dispatch(subscribe({ _id, type: 'category' }))),
    ).then(() => dispatch(showMessage(ALL_CATEGORIES_SUBSCRIBED)));
  };
}
