import { UrlMappingsKeys } from '@wix/url-mapper-utils';

const ForumUrlMappingsKeys = [
  UrlMappingsKeys.FORUM_CATEGORY,
  UrlMappingsKeys.FORUM_CATEGORY_PAGINATION,
  UrlMappingsKeys.FORUM_CATEGORY_CREATE_POST,
  UrlMappingsKeys.FORUM_CATEGORY_CREATE_QUESTION,
  UrlMappingsKeys.FORUM_POST,
  UrlMappingsKeys.FORUM_POST_EDIT,
  UrlMappingsKeys.FORUM_POST_PAGINATION,
  UrlMappingsKeys.FORUM_POST_DEEP_LINK_DATA,
  UrlMappingsKeys.FORUM_POST_PAGINATION_AND_DEEP_LINK_DATA,
];

const makePath = (location) =>
  (location.url || '')
    .replace(location.baseUrl, '')
    .split('/')
    .filter((part) => part);

const hasCustomForumSegments = async (wixCodeApi) => {
  const location = wixCodeApi.location;
  const segments = await wixCodeApi.site?.getCustomizedUrlSegments(location.url);
  return segments && ForumUrlMappingsKeys.includes(segments.key);
};

export const transformWixCodeLocation = async (wixCodeApi, sectionUrl = '', locationPath) => {
  const location = wixCodeApi.location;
  locationPath = locationPath || location.path;
  const lastPathPart = sectionUrl
    .split('/')
    .filter((part) => part)
    .pop();
  const indexOfLastPart = locationPath.indexOf(lastPathPart);

  let path = [];
  if (await hasCustomForumSegments(wixCodeApi)) {
    path = makePath(location);
  } else if (indexOfLastPart !== -1) {
    path = locationPath.slice(indexOfLastPart + 1);
  }

  return {
    url: location.url,
    baseUrl: location.baseUrl,
    // path: location.path,
    sectionUrl,
    pathname: '/' + path.join('/'),
    protocol: location.protocol,
    query: location.query,
    hash: location.hash,
  };
};
