import { OPEN_MODAL, CLOSE_MODAL } from '../modal-actions';

/* Don't forget to change provider!! */
export function openedModals({ allowUpdateToModals = [] } = {}) {
  return function (state = {}, action) {
    switch (action.type) {
      case OPEN_MODAL: {
        const { type } = action.payload;
        if (state[type] && !allowUpdateToModals.includes(type)) {
          return state;
        }
        return {
          ...state,
          [type]: { ...action.payload, meta: action.meta || {} },
        };
      }
      case CLOSE_MODAL: {
        return { ...state, [action.payload.type]: null };
      }
      default:
        return state;
    }
  };
}
