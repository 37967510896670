import {
  FETCH_EXTERNAL_LINKS_REQUEST,
  FETCH_EXTERNAL_LINKS_SUCCESS,
  FETCH_EXTERNAL_LINKS_FAILURE,
} from '../actions/fetch-external-links';

export function externalLinks(state = [], { type, payload } = {}) {
  switch (type) {
    case FETCH_EXTERNAL_LINKS_SUCCESS:
      return payload;
    case FETCH_EXTERNAL_LINKS_REQUEST:
    case FETCH_EXTERNAL_LINKS_FAILURE:
      return [];
    default:
      return state;
  }
}
