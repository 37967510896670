import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { CommonRequest } from '../types';
import { searchSuggestionsApi } from '../../api/search/search-suggestions.api';
import { SearchSuggestionsApiResult } from '../../api/search/models';
import { RootState } from '../types/store-types';
import { getBaseUrl } from '../../common/store/location/location-selectors';

export const fetchSearchSuggestionsPromisified = createPromisifiedAction(
  (query: string, limit: number) => {
    return (_dispatch: any, getState: any, { request }: { request: CommonRequest }) => {
      const state: RootState = getState();
      const baseUrl = getBaseUrl(state);

      return request.httpClient
        .request(
          searchSuggestionsApi({
            query,
            limit,
            baseUrl,
          }),
        )
        .then(({ data }) => data);
    };
  },
  ((data: SearchSuggestionsApiResult) => data) as any,
);
