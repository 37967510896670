import { createAction } from '@reduxjs/toolkit';

interface SetSavedAction {
  action: string;
  args: string;
}

export const SET_SAVED_ACTION = 'app/SET_SAVED_ACTION';

export const setSavedAction = createAction<SetSavedAction>(SET_SAVED_ACTION);
