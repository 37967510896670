import { parseInstance } from '@wix/communities-forum-client-commons';
import { AnyAction, Middleware } from '@reduxjs/toolkit';
import {
  PlatformAPIs,
  WixCodeApi,
} from '@wix/comments-ooi-client/dist/types/common/platform-types';
import { getInstanceValue } from '../../common/store/instance-values/instance-values-selectors';
import { isDummyVisitorId } from './bi-helpers';
import { BiLogger, BiEventsExecutor } from './bi-types';
import biLocationTracker from './bi-location-tracker';

export const getLoggerDefaultValues = (
  instance: string,
  isSite: boolean,
  wixCodeApi: WixCodeApi,
  platformAPIs: PlatformAPIs,
) => {
  const parsedInstance = parseInstance(instance);
  const { biToken, appDefId, uid, instanceId, siteOwnerId } = parsedInstance;
  const { visitorId, metaSiteId } = platformAPIs.bi || { visitorId: '', metaSiteId: '' };

  return {
    biToken,
    application_type: 'web',
    instance_id: instanceId,
    app_site_id: instanceId,
    app_id: appDefId,
    site_member_id: uid ?? null,
    _visitorId: visitorId,
    uuid: uid ?? null,
    ...getBiFieldsOverride(),
  };

  function getBiFieldsOverride() {
    if (isSite) {
      return {};
    }

    const user = wixCodeApi.user.currentUser;
    return {
      _msid: metaSiteId,
      _appId: appDefId,
      _instanceId: instanceId,
      _siteOwnerId: siteOwnerId,
      _siteMemberId: user.loggedIn ? user.id : '',
    };
  }
};

interface InitBiMiddleware {
  logger: BiLogger;
  executor: (args: BiEventsExecutor<any, any>) => void;
  instance: string;
  biLocation: typeof biLocationTracker;
  isSite: boolean;
  config: any;
  platformAPIs: PlatformAPIs;
  wixCodeApi: WixCodeApi;
}

export const initBiMiddleware = ({
  logger,
  executor,
  instance,
  biLocation,
  isSite,
  config,
  platformAPIs,
  wixCodeApi,
}: InitBiMiddleware) => {
  const defaults = getLoggerDefaultValues(instance, isSite, wixCodeApi, platformAPIs);
  const { visitorId, viewerSessionId } = platformAPIs.bi || {
    visitorId: '',
    viewerSessionId: '',
  };

  logger.updateDefaults(defaults);

  return createBiMiddleware(
    logger,
    executor,
    biLocation,
    isSite,
    config,
    viewerSessionId,
    visitorId,
  );
};

const createBiMiddleware = (
  logger: BiLogger,
  biEventsExecutor: (args: BiEventsExecutor<any, any>) => void,
  biLocation: typeof biLocationTracker,
  isSite: boolean,
  config: any,
  viewerSessionId: string,
  visitorId: string,
): Middleware => {
  return (store) => (next) => (action: AnyAction) => {
    next(action);

    try {
      const state = store.getState();
      if (!isDummyVisitorId(visitorId) || getInstanceValue(state, 'uid')) {
        biEventsExecutor({ action, state, logger, biLocation, isSite, config, viewerSessionId });
      }
    } catch (e) {
      log(`Event ${action.type} bi logger error: ${e}`);
    }
  };
};

const log = (message: string) => {
  process.env.NODE_ENV === 'development' && console.error(message);
};
