import { createAction } from '@reduxjs/toolkit';

interface ChangePage {
  page: string;
  meta: {
    bi: {
      buttonType: string;
    };
  };
}

export const CHANGE_PAGE = 'app/CHANGE_PAGE';

export const changePage = createAction<ChangePage>(CHANGE_PAGE);
