import { buildRoutesMap } from '@wix/communities-forum-client-commons';
import createAction from '../../services/create-action';

export const SET_CUSTOM_ROUTES = 'post/SET_CUSTOM_ROUTES';

export const setCustomRoutesAction = createAction(SET_CUSTOM_ROUTES);

export const setCustomRoutes =
  () =>
  async (dispatch, getState, { wixCodeApi }) => {
    const customRoutes = {};
    const routesMap = buildRoutesMap();
    await Promise.all(
      Object.keys(routesMap).map(async (key) => {
        customRoutes[key] =
          wixCodeApi.location &&
          wixCodeApi.location.buildCustomizedUrl &&
          (await wixCodeApi.location.buildCustomizedUrl(key, routesMap[key]));
      }),
    );
    dispatch(setCustomRoutesAction(customRoutes));
  };
