import { MembersMap } from '../../../api/members/get-members-list.api';
import { Member } from '../../../api/members/member.type';
import { RootState } from '../../../app/types/store-types';
import { FollowedMember, MembersInternalState } from './members-types';

export const getMembersState = (state: RootState): MembersInternalState => state.members;

export const getMembersCache = (state: RootState): MembersMap =>
  getMembersState(state).membersCache;

export const getCurrentUserId = (state: RootState) => getMembersState(state)?.currentUserId;

export const getCurrentUserIdentity = (state: RootState) => ({
  identityId: getCurrentUserId(state),
  identityType: 'MEMBER',
});

export const getCurrentUser = (state: RootState): Member | null => {
  const userId = getCurrentUserId(state);
  return userId ? getMembersCache(state)[userId] : null;
};

const getFollowedMembers = (state: RootState): FollowedMember[] =>
  getMembersState(state).followedMembers;

export const getFollowedMemberById = (state: RootState, id: string): FollowedMember | undefined =>
  getFollowedMembers(state).find((m) => m.id === id);
