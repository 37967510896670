import { showMessage, hideMessage } from './store/message-actions';
import { NAME } from './store/message-state-name';
import { message } from './store/message-reducer';

export { createMessageMiddleware } from './store/message-middleware';

export const messageActions = {
  showMessage,
  hideMessage,
};

export const messageReducer = { [NAME]: message };
