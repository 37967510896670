import { get } from 'lodash';
import { FETCH_POSTS_SUCCESS } from '../actions/fetch-posts';
import { FETCH_UPDATED_POSTS_SUCCESS } from '../actions/fetch-updated-posts';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { MOVE_POST_SUCCESS } from '../actions/move-post';

export const UNCATEGORIZED_POSTS = 'UNCATEGORIZED';

const initialState = {
  posts: {
    [UNCATEGORIZED_POSTS]: {
      entitiesByPage: {},
      entityCount: 0,
      sort: null,
    },
  },
  memberPosts: {},
};

// posts pagination data is saved by category id. it's used for pagination and ordering.
// when sort value changes we reset the entitiesByPage, so that sorting is correct.
const createPostsEntities = (entities, meta, currentState) => {
  const categoryId = meta.categoryId || UNCATEGORIZED_POSTS;
  const siteMemberId = meta.siteMemberId;
  const subState = siteMemberId
    ? get(currentState, `memberPosts.${siteMemberId}.${categoryId}`, {})
    : get(currentState, `posts.${categoryId}`, {});
  const shouldResetEntitiesState = get(subState, 'sort') !== meta.sort;

  const stateByCategoryId = {
    entitiesByPage: {
      ...(shouldResetEntitiesState ? {} : subState.entitiesByPage),
      [meta.page]: entities.map((entity) => entity._id),
    },
    entityCount: meta.entityCount,
    sort: meta.sort,
  };

  if (siteMemberId) {
    return {
      ...currentState,
      memberPosts: {
        ...currentState.memberPosts,
        [siteMemberId]: {
          ...currentState.memberPosts[siteMemberId],
          [categoryId]: stateByCategoryId,
        },
      },
    };
  }

  return {
    ...currentState,
    posts: {
      ...currentState.posts,
      [categoryId]: stateByCategoryId,
    },
  };
};

const removePost = (posts, deletedPostId) =>
  Object.entries(posts).reduce((result, [key, value]) => {
    let containsDeleted = false;
    result[key] = {
      ...value,
      entitiesByPage: Object.entries(value.entitiesByPage).reduce(
        (entitiesResult, [page, postsIds]) => {
          containsDeleted = postsIds.includes(deletedPostId);
          entitiesResult[page] = postsIds.filter((id) => id !== deletedPostId);
          return entitiesResult;
        },
        {},
      ),
    };

    if (containsDeleted) {
      result[key].entityCount -= 1;
    }
    return result;
  }, {});

export default (state = initialState, { type, payload, meta = {} } = {}) => {
  switch (type) {
    case FETCH_UPDATED_POSTS_SUCCESS:
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        ...createPostsEntities(payload, meta, state),
      };
    case MOVE_POST_SUCCESS:
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        posts: removePost(state.posts, payload),
      };
    default:
      return state;
  }
};
