import { createAction } from '@reduxjs/toolkit';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_PIN } from '../components/messages/message-types';
import { getPost } from '../selectors/post-selectors';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest, Post } from '../types';

export const PIN_POST_REQUEST = 'post/PIN_REQUEST';
export const PIN_POST_SUCCESS = 'post/PIN_SUCCESS';
export const PIN_POST_FAILURE = 'post/PIN_FAILURE';

export const pinPostRequest =
  createAction<{ expiresInMinutes?: number | null; postId: string }>(PIN_POST_REQUEST);

export function pinPost(id: string, expiresInMinutes?: number | null) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    const state = getState();
    dispatch(pinPostRequest({ expiresInMinutes, postId: id }));

    const hasBody = expiresInMinutes !== undefined;
    const body = hasBody
      ? {
          expiresInMinutes,
        }
      : {};

    const promise = hasBody
      ? request.put<Post>(`/platformized/v1/posts/${id}/pin`, body)
      : request.post<undefined>(`/posts/${id}/pin`, {});

    return promise
      .then((post) => {
        post = post?._id ? post : { ...getPost(state, id), isPinned: true };
        dispatch(showMessage(POST_PIN));
        return dispatch({
          type: PIN_POST_SUCCESS,
          payload: post,
        });
      })
      .catch(() =>
        dispatch({
          type: PIN_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
