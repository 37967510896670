import { AppThunk } from '../types/store-types';
import { getMissingMembersList } from '../containers/post-reactions/post-reactions-selectors';
import { openModal } from '../../common/modals/framework/store/modal-actions';
import { MODAL_TYPE_LIKE_LIST } from '../components/modals/like-list-modal/like-list-modal-type';
import { MODAL_TYPE_EMOTIONS_LIST } from '../components/modals/emotions-list-modal/emotions-list-modal-type';
import { ReactionsConfig } from '../containers/post-reactions/post-reactions-config.utils';
import { fetchMembersList } from '../../common/store/members/members-actions';
import { ReactionsDisplayState } from '../containers/post-reactions/post-reaction-types';

export type OpenReactionsModalProps = {
  postId: string;
  reactionsConfig: ReactionsConfig;
  reactions: ReactionsDisplayState;
};

export function openReactionsModal({
  postId,
  reactionsConfig,
  reactions,
}: OpenReactionsModalProps): AppThunk {
  return (dispatch, getState, { request }) => {
    const modalType =
      reactionsConfig.type === 'likes' ? MODAL_TYPE_LIKE_LIST : MODAL_TYPE_EMOTIONS_LIST;

    dispatch(
      openModal(modalType, {
        reactions,
        config: reactionsConfig,
        postId,
      }),
    );

    const missingMembersList = getMissingMembersList(getState(), postId);

    if (missingMembersList.length > 0) {
      dispatch(fetchMembersList({ memberIds: missingMembersList }));
    }
  };
}
