import { get } from 'lodash';
import { getStaticsVersionFromUrl } from './statics-version';
import { AppParams, FlowAPI, Sentry } from '../../app/types/platform-types';

export const ENVIRONMENT = {
  APP: 'App',
  RPW: 'RPW',
};

export const normalizeError = (error: any) => {
  if (
    typeof Response !== 'undefined' &&
    error instanceof Response &&
    error.constructor.name !== 'Error'
  ) {
    return Error(`Failed to fetch ${error.url} with status ${error.status} - ${error.statusText}`);
  }

  if (!error) {
    return new Error('Error is undefined.');
  }

  return error;
};

export const decorateActionsWithLogger = <T extends { [key: string]: any }>(
  actions: T,
  sentry: Sentry,
): T => {
  const decoratedActions: any = {};
  const decorate =
    (action: any, key: string) =>
    (...args: any[]) => {
      const captureToSentry = (e: any) =>
        sentry.captureException(normalizeError(e), {
          extra: { action: key },
          tags: { capturedBy: 'action monitor' },
        });
      try {
        const response = action(...args);
        if (response instanceof Promise) {
          response.catch((error) => {
            if (error && error.status !== 200 && error.status !== 500) {
              return;
            }

            captureToSentry(error);
          });
        }
        return response;
      } catch (error) {
        captureToSentry(error);
      }
    };

  Object.keys(actions).forEach((key) => {
    const action = actions[key];
    decoratedActions[key] = decorate(action, key);
  });

  return decoratedActions;
};

export const createSentryMonitor = ({
  flowAPI,
  isSSR,
  environment,
  appParams,
}: {
  flowAPI: FlowAPI;
  isSSR: boolean;
  environment: string;
  appParams: AppParams;
}) => {
  const url = get(appParams, 'baseUrls.staticsBaseUrl', '');
  const version = getStaticsVersionFromUrl(url);

  const raven = flowAPI.sentry;

  if (raven) {
    if (raven.setEnvironment) {
      raven.setEnvironment(isSSR ? `${environment}-Worker-SSR` : `${environment}-Worker-CSR`);
    }

    if (raven.setRelease) {
      raven.setRelease(version);
    }
  }

  return {
    raven,
    captureException: (...args: Parameters<FlowAPI['sentry']['captureException']>) => {
      if (!raven) {
        return;
      }

      raven.captureException(...args);
      return raven.lastEventId();
    },
    captureMessage: (...args: Parameters<FlowAPI['sentry']['captureMessage']>) => {
      if (!raven) {
        return;
      }

      raven.captureMessage(...args);
      return raven.lastEventId();
    },
  };
};
