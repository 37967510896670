import { uniqBy, sortBy, findIndex } from 'lodash';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/actions/fetch-categories';
import { DELETE_CATEGORY_SUCCESS } from '../actions/delete-category';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from '../actions/subscribe';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from '../actions/unsubscribe';
import { FETCH_CATEGORY_SUCCESS } from '../actions/fetch-category';
import { UPDATE_CATEGORIES } from '../actions/update-categories';
import { FETCH_CATEGORIES_GROUPS_SUCCESS } from '../../common/actions/fetch-categories-groups';

const initialState = [];
const SORT_BY = 'rank';

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_CATEGORIES_GROUPS_SUCCESS:
      return state.map((category) => ({
        ...category,
        groups: payload[category._id] || category.groups,
      }));
    case UPDATE_CATEGORIES:
      return payload;
    case FETCH_CATEGORIES_SUCCESS:
      return sortBy(uniqBy([...payload, ...state], '_id'), SORT_BY);
    case FETCH_CATEGORY_SUCCESS: {
      const categoryIndex = findIndex(state, (category) => category._id === payload._id);
      const category = state[categoryIndex];
      return category
        ? sortBy(
            [
              ...state.slice(0, categoryIndex),
              {
                ...category,
                ...payload,
              },
              ...state.slice(categoryIndex + 1, state.length),
            ],
            SORT_BY,
          )
        : state;
    }
    case DELETE_CATEGORY_SUCCESS:
      return state.filter((category) => category._id !== payload);
    case SUBSCRIBE_REQUEST:
      return setIsSubscribed(state, payload, true);

    case SUBSCRIBE_FAILURE:
      return setIsSubscribed(state, payload, false);

    case UNSUBSCRIBE_REQUEST:
      return setIsSubscribed(state, payload, false);

    case UNSUBSCRIBE_FAILURE:
      return setIsSubscribed(state, payload, true);
    default:
      return state;
  }
};

function setIsSubscribed(state, { type, _id }, isSubscribed) {
  if (type !== 'category') {
    return state;
  }

  const index = findIndex(state, (category) => category._id === _id);
  return [
    ...state.slice(0, index),
    {
      ...state[index],
      isSubscribed,
    },
    ...state.slice(index + 1),
  ];
}
