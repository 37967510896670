import { orderBy } from 'lodash';
import {
  FETCH_SIMILAR_POSTS_REQUEST,
  FETCH_SIMILAR_POSTS_SUCCESS,
  FETCH_SIMILAR_POSTS_FAILURE,
} from '../actions/fetch-similar-posts';

export function similarPosts(state = [], { type, payload } = {}) {
  switch (type) {
    case FETCH_SIMILAR_POSTS_SUCCESS:
      return orderBy(payload, ['totalComments'], ['desc']);
    case FETCH_SIMILAR_POSTS_REQUEST:
    case FETCH_SIMILAR_POSTS_FAILURE:
      return [];
    default:
      return state;
  }
}
