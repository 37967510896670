import { createPromisifiedAction } from '../../actions-promisifier/create-promisified-action';

export const requestFileDownloadUrlPromisified = createPromisifiedAction(
  (path) =>
    (dispatch, getState, { request }) => {
      return request(`/media/v2/download-url?path=${path}`);
    },
  (c) => c,
  (e) => e.status,
);
