export const encodeQuery = (query) =>
  query &&
  query
    .replace(/#/g, '~num~')
    .replace(/%/g, '~proc~')
    .replace(/\//g, '~slash~')
    .replace(/:/g, '~colon~')
    .replace(/;/g, '~semicolon~')
    .replace(/\?/g, '~question~')
    .replace(/'/g, '~atos~')
    .replace(/\\/g, '~backslash~')
    .replace(/&/g, '~and~')
    .replace(/\./g, '~dot~')
    .replace(/\(/g, '~lbracket~')
    .replace(/\)/g, '~rbracket~')
    .replace(/,/g, '~comma~')
    .replace(/\s/g, '-');
export const decodeQuery = (query) =>
  query &&
  query
    .replace(/~num~/g, '#')
    .replace(/~proc~/g, '%')
    .replace(/~slash~/g, '/')
    .replace(/~colon~/g, ':')
    .replace(/~semicolon~/g, ';')
    .replace(/~question~/g, '?')
    .replace(/~atos~/g, `'`)
    .replace(/~backslash~/g, '\\')
    .replace(/~and~/g, '&')
    .replace(/~dot~/g, '.')
    .replace(/~lbracket~/g, '(')
    .replace(/~rbracket~/g, ')')
    .replace(/~comma~/g, ',')
    .replace(/-/g, ' ');
export const decodeSpaces = (query) => query && query.replace(/-/g, ' ');
