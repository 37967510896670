import { FETCH_COUNTERS_SUCCESS } from '../actions/fetch-counters';
import { UPDATE_POST_COUNTERS, UPDATE_CATEGORY_COUNTERS } from '../actions/category-socket';

const MAX_COUNTERS_SIZE = 200; // in theory if user keeps navigating a lot this could get very large, to prevent that we reset it

export function counters(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_COUNTERS_SUCCESS:
      const result = [].concat(payload.categories || []).concat(payload.posts);

      return {
        ...(Object.keys(state).length < MAX_COUNTERS_SIZE && state),
        ...result.reduce((acc, res) => {
          const { _id, ...rest } = res;
          acc[_id] = rest;
          return acc;
        }, {}),
      };
    case UPDATE_POST_COUNTERS:
    case UPDATE_CATEGORY_COUNTERS:
      const { _id, ...rest } = payload;
      return { ...state, [_id]: { ...state[_id], ...rest } };
    default:
      return state;
  }
}
