import { FETCH_LOCAL_STORAGE_SUCCESS } from '../actions/fetch-local-storage';
import { SET_LOCAL_STORAGE_ITEM_SUCCESS } from '../actions/set-local-storage-item';

export const localStorage = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_LOCAL_STORAGE_SUCCESS:
      return { ...payload };
    case SET_LOCAL_STORAGE_ITEM_SUCCESS:
      return { ...state, ...{ [payload.key]: payload.value } };
    default:
      return state;
  }
};
