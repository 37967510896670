import { FETCH_PAID_PLANS_SUCCESS } from '../actions/fetch-paid-plans';

export function paidPlans(state = [], action) {
  switch (action.type) {
    case FETCH_PAID_PLANS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
