import { createAction } from '@reduxjs/toolkit';
import { getMembersList, MembersMap } from '../../../api/members/get-members-list.api';
import { AppThunk } from '../../../app/types/store-types';
import { fetchUserSuccess } from '../../actions/fetch-user';

export const FETCH_MEMBERS_LIST_REQUEST = 'members-list/FETCH_REQUEST';
export const FETCH_MEMBERS_LIST_SUCCESS = 'members-list/FETCH_SUCCESS';
export const FETCH_MEMBERS_LIST_FAILURE = 'members-list/FETCH_FAILURE';

export type FetchMembersListAction =
  | ReturnType<typeof fetchMembersListRequest>
  | ReturnType<typeof fetchMembersListSuccess>
  | ReturnType<typeof fetchMembersListFailure>
  | ReturnType<typeof fetchUserSuccess>;

export const fetchMembersListRequest = createAction(
  FETCH_MEMBERS_LIST_REQUEST,
  (payload: { memberIds: string[] }) => ({
    payload,
  }),
);

export const fetchMembersListSuccess = createAction(
  FETCH_MEMBERS_LIST_SUCCESS,
  (payload: { members: MembersMap; followedMembers: string[] }) => ({
    payload,
  }),
);

export const fetchMembersListFailure = createAction(
  FETCH_MEMBERS_LIST_FAILURE,
  (payload: { memberIds: string[]; error: object }) => ({
    payload,
  }),
);

export function fetchMembersList({ memberIds }: { memberIds: string[] }): AppThunk {
  return (dispatch, _getState, { request }) => {
    dispatch(fetchMembersListRequest({ memberIds }));
    // limit as no more than 100 items per request are supported from the members backend
    const membersToFetch = memberIds.length <= 100 ? memberIds : memberIds.slice(0, 100);
    const promise = request.httpClient.request(getMembersList({ memberIds: membersToFetch }));

    return promise
      .then((results) => {
        dispatch(
          fetchMembersListSuccess({
            members: results.data.members,
            followedMembers: results.data.followedMembers,
          }),
        );
      })
      .catch((err) => dispatch(fetchMembersListFailure({ memberIds, error: err })))
      .then(() => promise);
  };
}
