import { map, pick } from 'lodash';

export const UPDATE_CATEGORY_RANKS_REQUEST = 'categories/UPDATE_RANKS_REQUEST';
export const UPDATE_CATEGORY_RANKS_SUCCESS = 'categories/UPDATE_RANKS_SUCCESS';
export const UPDATE_CATEGORY_RANKS_FAILURE = 'categories/UPDATE_RANKS_FAILURE';

export const updateCategoryRanksRequest = () => ({ type: UPDATE_CATEGORY_RANKS_REQUEST });
export const updateCategoryRanksSuccess = (categories) => ({
  type: UPDATE_CATEGORY_RANKS_SUCCESS,
  payload: categories,
});
export const updateCategoryRanksFailure = () => ({ type: UPDATE_CATEGORY_RANKS_FAILURE });

export const updateCategoryRanks =
  (categories) =>
  (dispatch, getState, { request }) => {
    const data = map(categories, (category) => pick(category, ['_id', 'rank', 'parentId']));

    dispatch(updateCategoryRanksRequest());
    const promise = request.patch('/categories', data);
    return promise
      .then((categories) => dispatch(updateCategoryRanksSuccess(categories)))
      .catch(() => dispatch(updateCategoryRanksFailure()))
      .then(() => promise);
  };
