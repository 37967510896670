import { InteractionController } from './interaction-controller';
import { interactionError } from './interactions.actions';

export default function createInteractionsMiddleware(fedopsLogger, options = {}) {
  return (store) => (next) => (action) => {
    const interaction = options[action.type];
    if (interaction) {
      const hasEnded = InteractionController.interactionEnded(interaction, fedopsLogger);
      if (hasEnded === false) {
        store.dispatch(interactionError({ interaction, additionalInfo: action.payload }));
      }
    }
    return next(action);
  };
}
