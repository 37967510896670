
  import userController from '/home/builduser/work/ccc61f40c31d816c/packages/communities-forum-client/src/components/Forum/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  
var initI18n = null;

  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://243161cf60694b9396b700815e4c5801@sentry-next.wixpress.com/431',
    id: '243161cf60694b9396b700815e4c5801',
    projectName: 'communities-forum-client',
    teamName: 'wix-forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"centralized":true,"scopes":["wix-forum"]};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var biConfig = null;

  var defaultTranslations = null;

  var fedopsConfig = {"enabled":true};

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/ccc61f40c31d816c/packages/communities-forum-client/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "communities-forum-client",
    componentName: "Forum",
    appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
    componentId: "1489040e-001f-4631-55a9-2c29b4417126",
    projectName: "communities-forum-client",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: true,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/ccc61f40c31d816c/packages/communities-forum-client/src/components/Forum/controller.ts",
  };

  export const controller = _controller
  export default controller;
