import { unsubscribe } from './unsubscribe';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { ALL_CATEGORIES_UNSUBSCRIBED } from '../components/messages/message-types';
import { getSubscribedCategories } from '../../common/selectors/categories-selectors';

export function unsubscribeFromAllCategories() {
  return (dispatch, getState) => {
    const subscribedCategories = getSubscribedCategories(getState());
    return Promise.all(
      subscribedCategories.map(({ _id }) => dispatch(unsubscribe({ _id, type: 'category' }))),
    ).then(() => dispatch(showMessage(ALL_CATEGORIES_UNSUBSCRIBED)));
  };
}
