import { SET_SAVED_ACTION } from './set-saved-action';

export function savedAction(state = {}, action) {
  switch (action.type) {
    case SET_SAVED_ACTION:
      return action.payload;
    default:
      return state;
  }
}
