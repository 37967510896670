import { FedopsLogger } from '../../app/types/platform-types';

// create a singleton instance of the interaction controller
// that is defined by a string

export class InteractionController {
  private static interactionsStack: { [key: string]: number };

  private constructor() {}

  private static getStackSize(interaction: string) {
    if (!InteractionController.interactionsStack) {
      InteractionController.interactionsStack = {};
    }
    if (!InteractionController.interactionsStack[interaction]) {
      InteractionController.interactionsStack[interaction] = 0;
    }
    return InteractionController.interactionsStack[interaction];
  }

  private static setStackSize(interaction: string, size: number) {
    if (!InteractionController.interactionsStack) {
      InteractionController.interactionsStack = {};
    }
    InteractionController.interactionsStack[interaction] = size;
  }

  static interactionStarted(interaction: string, fedopsLogger: FedopsLogger) {
    if (!fedopsLogger) {
      console.error(`fedopsLogger is not set, ${interaction} tried to interact`);
      return;
    }
    const stackSize = InteractionController.getStackSize(interaction);
    if (stackSize === undefined) {
      return;
    }

    fedopsLogger.interactionStarted(interaction);
    InteractionController.setStackSize(interaction, stackSize + 1);
  }

  static interactionEnded(interaction: string, fedopsLogger: FedopsLogger) {
    if (!fedopsLogger) {
      console.error(`fedopsLogger is not set, ${interaction} tried to interact`);
      return;
    }

    const stackSize = InteractionController.getStackSize(interaction);
    if (!stackSize) {
      return false;
    }

    fedopsLogger.interactionEnded(interaction);
    InteractionController.setStackSize(interaction, stackSize - 1);
  }
}
