import { SHOW_MESSAGE, HIDE_MESSAGE } from './message-actions';

const initialState = {};

export function message(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return action.payload;
    case HIDE_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
