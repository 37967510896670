import { createAction } from '@reduxjs/toolkit';

export const FILE_DOWNLOAD_REQUEST = 'file-upload-plugin/DOWNLOAD_REQUEST';

interface FileDownloadRequest {
  fileId: string;
  fileExtension: string;
  mimeType: string;
  size: number;
  origin: string;
  isSuccessful: boolean;
}

export const fileDownloadRequest = createAction<FileDownloadRequest>(FILE_DOWNLOAD_REQUEST);
