import createAction from '../services/create-action';
import { getTranslations } from '../store/translations/translations-selectors';
import { setTranslations } from '../store/translations/translations-actions';
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime';

export const SET_EXPERIMENTS_SUCCESS = 'experiments/SET_EXPERIMENTS_SUCCESS';

export const setExperimentsSuccess = createAction(SET_EXPERIMENTS_SUCCESS);

export function setExperiments(experimentsBag = {}) {
  return async (dispatch, getState) => {
    const currentTranslations = getTranslations(getState());
    const translationsWithExperiments = abTranslate(experimentsBag, currentTranslations);
    dispatch(setTranslations(translationsWithExperiments));

    return dispatch(setExperimentsSuccess(experimentsBag));
  };
}
