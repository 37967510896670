import { omit } from 'lodash';
import { LOAD_MODAL_START, LOAD_MODAL_STOP } from '../modal-actions';

export function isLoading(state = {}, action) {
  switch (action.type) {
    case LOAD_MODAL_START: {
      const { type } = action.payload;
      if (state[type]) {
        return state;
      }
      return {
        ...state,
        [type]: true,
      };
    }
    case LOAD_MODAL_STOP: {
      return omit(state, action.payload.type);
    }
    default:
      return state;
  }
}
