import { STATE_KEY_NAME } from './constants';
import { SET_ROUTER_MATCH } from './router-actions';

const reducer = (state = { currentMatch: {}, previousMatches: [] }, action) => {
  switch (action.type) {
    case SET_ROUTER_MATCH:
      return {
        currentMatch: action.payload,
        previousMatches: [action.payload, ...state.previousMatches].slice(0, 10),
      };
    default:
      return state;
  }
};

export const routerReducer = { [STATE_KEY_NAME]: reducer };
