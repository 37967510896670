import { unsubscribe } from './unsubscribe';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_UNSUBSCRIBED } from '../components/messages/message-types';

export function unsubscribeFromPost(_id) {
  return (dispatch) =>
    dispatch(unsubscribe({ _id, type: 'post' })).then(() =>
      dispatch(showMessage(POST_UNSUBSCRIBED)),
    );
}
