import { Dispatch } from '@reduxjs/toolkit';
import { resolvePromisifiedAction } from './store/promisified-actions-actions';

export function createPromisifiedAction<T extends Dispatch>(
  action: any,
  dataSelector: void | ((arg: any) => any) = console.error('dataSelector is required'),
  errorSelector = (e: any) => e,
) {
  return (args: any, correlationId?: string) => (dispatch: T) => {
    if (!dataSelector) {
      return;
    }
    dispatch(action(...args)).then(
      (result: any) => {
        return dispatch(resolvePromisifiedAction(true, correlationId, dataSelector(result)));
      },
      (err: any) => {
        return dispatch(resolvePromisifiedAction(false, correlationId, errorSelector(err)));
      },
    );
  };
}
