import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../types/store-types';
import { CommonRequest } from '../types';
import { showMessage } from '../../common/messages/framework/store/message-actions';
import { POST_REPORTED } from '../components/messages/message-types';

interface Report {
  postId: string;
  reportType: string;
}

interface ReportPostSuccess {
  _id: string;
  reportType: string;
}

export const REPORT_POST_REQUEST = 'post/REPORT_REQUEST';
export const REPORT_POST_SUCCESS = 'post/REPORT_SUCCESS';
export const REPORT_POST_FAILURE = 'post/REPORT_FAILURE';

export const reportPostRequest = createAction(REPORT_POST_REQUEST);
export const reportPostSuccess = createAction<ReportPostSuccess>(REPORT_POST_SUCCESS);
export const reportPostFailure = createAction(REPORT_POST_FAILURE);

export function reportPost(report: Report) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    dispatch(reportPostRequest());

    const promise = request.post(`/posts/${report.postId}/report`, {
      reportType: report.reportType,
    });

    return promise
      .then(() => {
        dispatch(
          reportPostSuccess({
            _id: report.postId,
            reportType: report.reportType,
          }),
        );

        dispatch(showMessage(POST_REPORTED));
      })
      .catch(() => dispatch(reportPostFailure()))
      .then(() => promise);
  };
}
