import { FETCH_FORUM_DATA_SUCCESS } from '../actions/fetch-forum-data';
import { UPDATE_FORUM_DATA_SUCCESS } from '../actions/update-forum-data';
import { SET_FORUM_DATA } from '@wix/communities-forum-client-commons';

export default function forumData(state = {}, action) {
  switch (action.type) {
    case SET_FORUM_DATA:
    case FETCH_FORUM_DATA_SUCCESS:
    case UPDATE_FORUM_DATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
