import { Duplexer } from '@wix/duplexer-js';

let duplexerClient;
export default function createDuplexerClient({
  duplexerSocketsServerUrl,
  getInstance,
  useHttpStreaming,
}) {
  if (duplexerClient) {
    try {
      duplexerClient.connection.close();
    } catch (error) {}
  }
  duplexerClient = new Duplexer(duplexerSocketsServerUrl, {
    instanceUpdater: { getInstance },
    _experimentalHttpStreaming: useHttpStreaming,
  });
  return duplexerClient;
}

export function getDuplexerClient() {
  return duplexerClient;
}
