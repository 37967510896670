import { Ordering } from '@wix/comments-ooi-client/controller';
import { isQuestion } from '@wix/communities-forum-client-commons';
import { CommentsOrdering } from '../types';

export const CommentsOrderingMap = {
  latest_first: Ordering.NEWEST_FIRST,
  oldest_first: Ordering.OLDEST_FIRST,
  most_votes_first: Ordering.MOST_VOTES_FIRST,
  least_votes_first: Ordering.LEAST_VOTES_FIRST,
  most_reactions_first: Ordering.MOST_REACTIONS_FIRST,
};

const getConfig = (ordering: Ordering, loadMaxCommentsInitially: boolean) => ({
  replyShowMoreLimit: 8,
  ordering,
  initialPage: {
    commentLimit: loadMaxCommentsInitially ? 100 : 20,
    replyLimit: 3,
  },
  pagination: {
    commentLimit: 20,
    replyLimit: 3,
  },
  maxPagesBeforeDrop: {
    commentPages: 2,
    replyPages: 4,
  },
});

export const getPaginationConfig = (
  post: any,
  commentsOrdering: CommentsOrdering,
  loadMaxCommentsInitially: boolean,
) => {
  return {
    ...(isQuestion(post?.postType)
      ? getConfig(Ordering.MOST_VOTES_FIRST, loadMaxCommentsInitially)
      : getConfig(Ordering.NEWEST_FIRST, loadMaxCommentsInitially)),
    ...(commentsOrdering ? { ordering: CommentsOrderingMap[commentsOrdering] } : {}),
  };
};
