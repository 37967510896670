import { createAction } from '@reduxjs/toolkit';

interface RouterMatch {
  pathname: any;
  prevMatches?: any[];
  params: {
    categorySlug: string;
    postSlug: string;
    query: string;
  };
}

export const SET_ROUTER_MATCH = 'router/SET_MATCH';

export const setRouterMatch = createAction<RouterMatch>(SET_ROUTER_MATCH);
