import { isNumber } from 'lodash';
import { FETCH_USER_SUCCESS } from '../../actions/fetch-user';
import { USER_JOIN_COMMUNITY_SUCCESS } from '../../actions/join-community';
import { USER_PRIVACY_STATUS_PUBLIC } from '@wix/communities-forum-universal/dist/src/constants/user-privacy-status';
import { CREATE_POST_SUCCESS } from '../../../app/actions/create-post';

export function currentUser(state = null, { type, payload }) {
  switch (type) {
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        remainingPosts: isNumber(state.remainingPosts)
          ? state.remainingPosts - 1
          : state.remainingPosts,
      };
    case FETCH_USER_SUCCESS:
      return payload;
    case USER_JOIN_COMMUNITY_SUCCESS:
      return { ...state, privacyStatus: USER_PRIVACY_STATUS_PUBLIC };
    default:
      return state;
  }
}
