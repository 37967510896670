import { FORUM_NOT_INSTALLED_ERROR } from '../constants/errors';

export const handleInitialRequestFailure = (action: string) => (error: any) => {
  if (!error) {
    throw new Error(`Undefined error on ${action}`);
  }
  if (error && error.status === 404) {
    throw FORUM_NOT_INSTALLED_ERROR;
  }

  throw error;
};
