import { isInWix } from './is-in-wix';

const wixInstanceId = '33750584-5cda-44e1-8738-62a9139347b3';

const noop = () => {};

const WixPolyfill = {
  addEventListener: noop,
  getAdsOnPage: noop,
  isApplicationInstalled: (appId, cb) => cb(false),
  removeEventListener: noop,
  currentMember: (cb) => cb(null),
  requestLogin: noop,
  logOutCurrentMember: noop,
  replaceSectionState: noop,
  closeWindow: noop,
  setHeight: noop,
  resizeComponent: noop,
  getBoundingRectAndOffsets: noop,
  WindowOrigin: {},
  WindowPlacement: {},
  getCurrentPageId: (cb) => cb('pageId'),
  getSitePages: () => [{ id: '/' }],
  getSiteInfo: noop,
  navigateToPage: noop,
  scrollTo: noop,
  getSiteMap: (cb) => cb([]),
  getPublicAPI: noop,
  getSiteTextPresets: noop,
  Events: {},
  PubSub: {
    publish: noop,
    subscribe: noop,
    unsubscribe: noop,
  },
  openPopup: noop,
  Utils: {
    getUid: () => '00000000-0000-0000-0000-000000000001',
    getSiteOwnerId: () => '00000000-0000-0000-0000-000000000001',
    getSectionUrl: () => `${window.location.protocol}//${window.location.host}/`,
    getInstanceId: () => wixInstanceId,
    getInstanceValue: () => '00000000-0000-0000-0000-000000000000',
    getViewMode: () => '',
    navigateToSection: noop,
  },
  Performance: {
    applicationLoaded: noop,
    applicationLoadingStep: noop,
  },
  Theme: {
    BARE: 0,
  },
  Styles: {
    getSiteTextPresets: noop,
    getSiteColors: noop,
    getStyleParams: noop,
  },
  Dashboard: {
    getEditorUrl: noop,
  },
  Settings: {
    setHelpArticle: noop,
    triggerSettingsUpdatedEvent: noop,
  },
  Metadata: {
    removeAppMetadata: noop,
    setAppMetadata: noop,
  },
};

export default typeof window !== 'undefined'
  ? isInWix()
    ? window.Wix || WixPolyfill
    : WixPolyfill
  : WixPolyfill;
