import { AppThunk } from '../types/store-types';

export const focusCommentBox = (resourceId: string): AppThunk => {
  return (_dispatch, _getState, { wixCommentsApiRef }) => {
    return wixCommentsApiRef.current?.affect.tryOpenCommentBox(resourceId, {
      shouldFocus: true,
      shouldScroll: true,
    });
  };
};
