import { WIX_EVENTS, WIX_BOOKINGS, WIX_ECOM } from '@wix/app-definition-ids';

// TODO: import these constants from ricos when it doesn't result in a +50kb bundle.
export const VERTICALS = {
  EVENT: 'event',
  BOOKING: 'booking',
  STORES: 'product',
};

export const verticalsMap = {
  [VERTICALS.EVENT]: { appDefinitionId: WIX_EVENTS, sectionId: 'events' },
  [VERTICALS.BOOKING]: { appDefinitionId: WIX_BOOKINGS, sectionId: 'Booking Service Page' },
  [VERTICALS.STORES]: { appDefinitionId: WIX_ECOM, sectionId: 'product_page' },
};
