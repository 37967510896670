import { filter } from 'lodash';
import { VERTICALS, verticalsMap } from '../constants/vertical-embed';

export const SET_INSTALLED_APPS = 'verticalEmbed/SET_APPS';

export function setInstalledApps() {
  return (dispatch, getState, { wixCodeApi }) => {
    const payload = filter(VERTICALS, (key) =>
      wixCodeApi.site.isAppSectionInstalled(verticalsMap[key]),
    );
    dispatch({ type: SET_INSTALLED_APPS, payload });
  };
}
