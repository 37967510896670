import { FETCH_FOOTER_POSTS_SUCCESS } from '../actions/fetch-footer-posts';
import { DELETE_CATEGORY_SUCCESS } from '../actions/delete-category';
import { MARK_POSTS_AS_READ_SUCCESS } from '../containers/user-activity';

export function footerPosts(state = [], { type, payload } = {}) {
  switch (type) {
    case FETCH_FOOTER_POSTS_SUCCESS:
      return payload;
    case DELETE_CATEGORY_SUCCESS:
      return state.filter((p) => p.categoryId !== payload);
    case MARK_POSTS_AS_READ_SUCCESS:
      return state.reduce((result, post) => {
        result.push(post.categoryId === payload ? { ...post, isRead: true } : post);
        return result;
      }, []);
    default:
      return state;
  }
}
