import { validateLanguage } from '../../common/services/validate-language';
import { IWixAPI, ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getCurrentSiteLanguage = (
  wixCodeApi: IWixAPI,
  flowAPI: ControllerFlowAPI,
  devToolsStore?: { state?: { language?: string } },
) => {
  return validateLanguage(
    devToolsStore?.state?.language ||
      getMultilingualLanguage(wixCodeApi) ||
      flowAPI.environment.language,
  );
};

export const getMultilingualLanguage = (wixCodeApi: IWixAPI) =>
  wixCodeApi?.window?.multilingual?.currentLanguage;
