import { createUrlPattern } from './create-url-patter';

// normalize url. If it is unencoded - encode it. If it's encoded - avoid double encoding.
// Encoding is needed in order to match unicode characters (hebrew, arabic, emojis, etc.)
const normalizePathname = (pathname) => {
  let decoded = pathname;
  try {
    decoded = decodeURI(pathname);
  } catch {
    // Already decoded, skipping
  }
  let pathToMatch = encodeURI(decoded);

  if (pathToMatch === '') {
    pathToMatch = '/';
  }
  if (pathToMatch !== '/') {
    pathToMatch = pathToMatch.replace(/\/+$/g, '');
  }

  // Query params are not matched with 'url-pattern', so matching only main part
  return pathToMatch.split('?')[0];
};

export class Router {
  static resultIdentifier = '@@RouterResult';

  constructor() {
    this.routes = new Map();
    this.matchListeners = [];
  }

  add = (route, callback) => {
    this.routes.set(route, callback);
  };

  fallback = (redirect) => {
    this.fallbackRoute = redirect;
  };

  addCustomRouteHandler = (handler) => {
    this.customRouteHandler = handler;
  };

  handleCustomRoute = async (pathname, prevMatches) => {
    if (!this.customRouteHandler) {
      return;
    }
    const route = await this.customRouteHandler(pathname);
    return route && this.match(route, prevMatches.concat({ pathname: route }));
  };

  test = (pathname) => {
    const pathToMatch = normalizePathname(pathname);

    for (const [route] of this.routes) {
      const pattern = createUrlPattern(route);
      let match = pattern.match(pathToMatch);

      const isValidPage = match && match.page ? !isNaN(match.page) : true;
      if (!isValidPage) {
        match = undefined;
      }

      if (match) {
        return {
          pathname,
          route,
        };
      }
    }

    return { pathname, route: this.fallbackRoute };
  };

  match = (pathname, prevMatches = [], queryParams = {}) => {
    const createFallbackResult = () => ({
      pathname,
      params: {},
      prevMatches,
      route: this.fallbackRoute,
      queryParams,
      [Router.resultIdentifier]: true,
    });

    const triggerMatch = (result) => this.matchListeners.forEach((cb) => cb(result));

    return new Promise((resolve, reject) => {
      if (prevMatches.length >= 5) {
        if (this.fallbackRoute) {
          return resolve(createFallbackResult());
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject('too many redirects');
      }
      let match;
      const entries = this.routes.entries();
      let entry = entries.next();

      const pathToMatch = normalizePathname(pathname);

      while (!entry.done && !match) {
        const [route, callback] = entry.value;
        const pattern = createUrlPattern(route);
        match = pattern.match(pathToMatch);

        const isValidPage = match && match.page ? !isNaN(match.page) : true;
        if (!isValidPage) {
          match = undefined;
        }
        if (match?.commentsPage) {
          match.page = match.commentsPage;
        }

        if (match) {
          const result = {
            pathname,
            route,
            params: match,
            prevMatches,
            queryParams,
            [Router.resultIdentifier]: true,
          };

          triggerMatch(result);

          if (callback) {
            Promise.resolve(
              callback(result, (pathname) => this.match(pathname, prevMatches.concat(result))),
            )
              .then((val) => {
                resolve(val && val[Router.resultIdentifier] ? val : result);
              })
              .catch(reject);
          } else {
            resolve(result);
          }
        }

        entry = entries.next();
      }

      if (!match) {
        const resolveFn = (result) => {
          triggerMatch(result);
          resolve(result);
        };
        const promise = Promise.resolve(this.handleCustomRoute(pathname, prevMatches));
        promise
          .then((res) => (res ? resolveFn(res) : this.fallbackRoute && createFallbackResult()))
          .then((res) => {
            if (res) {
              resolveFn(res);
            } else if (!match) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('failed to match route');
            }
          });
      }
    });
  };

  onMatch = (callback) => this.matchListeners.push(callback);
}
