import { ReactionsInternalState } from './post-reaction-types';
import {
  ReactToPostActions,
  REACT_TO_POST_REQUEST,
  REACT_TO_POST_SUCCESS,
  REACT_TO_POST_FAILURE,
} from './post-reactions-actions';

export type PostState = { _id: string; reactions: ReactionsInternalState };

export function postReactionsReducer(state: PostState, action?: ReactToPostActions): PostState {
  switch (action?.type) {
    case REACT_TO_POST_REQUEST: {
      const { subaction, reactionCode, _id: postId, identity } = action.payload;

      if (state._id !== postId || state.reactions.type === 'PENDING') {
        return state;
      }

      return {
        ...state,
        reactions: {
          type: 'PENDING',
          reactedIdentities: state.reactions.reactedIdentities,
          recentReactions: state.reactions.recentReactions,
          action: subaction,
          reactionCode: subaction === 'REMOVE' ? undefined : reactionCode,
          identity,
        },
      };
    }
    case REACT_TO_POST_SUCCESS: {
      const { _id, reactedIdentities, recentReactions } = action.payload;

      if (state._id !== _id || state.reactions.type === 'IDLE') {
        return state;
      }

      return {
        ...state,
        reactions: {
          type: 'IDLE',
          reactedIdentities,
          recentReactions,
        },
      };
    }
    case REACT_TO_POST_FAILURE: {
      const { _id: postId } = action.payload;

      if (state._id !== postId || state.reactions.type === 'IDLE') {
        return state;
      }

      return {
        ...state,
        reactions: {
          type: 'IDLE',
          reactedIdentities: state.reactions.reactedIdentities,
          recentReactions: state.reactions?.recentReactions ?? [],
        },
      };
    }
    default:
      return state;
  }
}
