import { noop } from 'lodash';
import { getLanguage } from '../../common/store/basic-params/basic-params-selectors';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

export const requestLogin =
  (mode) =>
  (dispatch, getState, { wixCodeApi }) => {
    const options = {
      lang: getLanguage(getState()),
      modal: true,
    };
    if (mode !== undefined) {
      options.mode = mode;
    }
    return wixCodeApi.user.promptLogin(options).catch(noop);
  };

export const requestLoginPromisified = createPromisifiedAction(requestLogin, (result) => ({
  id: result.id,
  loggedIn: result.loggedIn,
  role: result.role,
}));
