import { SORT_BY_BEST_MATCH } from '@wix/communities-forum-client-commons';
import { SET_POSTS_PAGE_SORTING, SET_SEARCH_PAGE_SORTING } from '../actions/set-sorting';

export const sorting = (
  state = { categoryPage: undefined, searchPage: SORT_BY_BEST_MATCH },
  action = {},
) => {
  switch (action.type) {
    case SET_POSTS_PAGE_SORTING:
      return { ...state, categoryPage: action.payload };
    case SET_SEARCH_PAGE_SORTING:
      return { ...state, searchPage: action.payload };
    default:
      return state;
  }
};
