import { BiLogger, DefaultBiValues } from './bi-types';
import { Action } from '@reduxjs/toolkit';
import { isDebug } from '../../common/store/basic-params/basic-params-selectors';
import { RootState } from '../types/store-types';

interface TypedActionCreator<Type extends string> {
  (...args: any[]): Action<Type>;
  type: Type;
}

type ReportProps = Parameters<BiLogger['report']>[0];

export const createActionMatcher = <AllActions extends Action>(
  state: RootState,
  logger: BiLogger,
) => {
  type AllActionKeys = AllActions['type'];

  const actionMap: {
    [key in AllActionKeys]?: (action: any) => ReportProps;
  } = {};

  const matcher = {
    match: <T extends TypedActionCreator<string>, U extends (args: any) => any>(
      actions: T[],
      getBiEvent: (action: ReturnType<T>) => U | void,
      getEventParams: (action: ReturnType<T>) => Omit<Parameters<U>[0], DefaultBiValues> | void,
    ) => {
      actions.forEach((a) => {
        if (actionMap[a.type as AllActionKeys]) {
          throw new Error(`Action matcher for ${a.type} already exists`);
        } else {
          actionMap[a.type as AllActionKeys] = (action: ReturnType<T>) => {
            try {
              const biEvent = getBiEvent(action);
              if (!biEvent) {
                return;
              }
              return biEvent(getEventParams(action));
            } catch {
              isDebug(state) && console.debug('unmapped event', action);
            }
          };
        }
      });
      return matcher;
    },
    execute: (action: AllActions) => {
      const eventParamCallback = actionMap[action.type as AllActionKeys];
      const eventParams = eventParamCallback ? eventParamCallback(action) : null;
      if (eventParams && eventParams.params) {
        logger.report(eventParams);
      }
    },
  };

  return matcher;
};
