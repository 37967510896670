import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_POSTING_DISABLED } from './posting-disabled-modal/posting-disabled-modal-type';
import { movePostModalDataLoader } from './move-post-modal/move-post-modal-data-loader';
import { postingDisabledModalDataLoader } from './posting-disabled-modal/posting-disabled-modal-data-loader';
import { postPendingApprovalModalDataLoader } from './post-pending-approval-modal/post-pending-approval-modal-data-loader';
import { MODAL_TYPE_POST_PENDING_APPROVAL } from './post-pending-approval-modal/post-pending-approval-modal-type';

export const modalDataLoaderByType = {
  [MODAL_TYPE_MOVE_POST]: movePostModalDataLoader,
  [MODAL_TYPE_POSTING_DISABLED]: postingDisabledModalDataLoader,
  [MODAL_TYPE_POST_PENDING_APPROVAL]: postPendingApprovalModalDataLoader,
};
