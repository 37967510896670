import { URI as uri } from '@wix/communities-forum-client-commons';
import createAction from '../../common/services/create-action';

import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { markPostsAsSeen } from '../containers/user-activity';
import { fetchCounters } from './fetch-counters';

const mapBestAnswerToMarkedComments = (posts) => {
  return (
    posts &&
    posts.map((post) => ({
      ...post,
      markedComments: post.bestAnswerCommentId && [post.bestAnswerCommentId],
    }))
  );
};

export const FETCH_FOOTER_POSTS_REQUEST = 'posts/FETCH_FOOTER_POSTS_REQUEST';
export const FETCH_FOOTER_POSTS_SUCCESS = 'posts/FETCH_FOOTER_POSTS_SUCCESS';
export const FETCH_FOOTER_POSTS_FAILURE = 'posts/FETCH_FOOTER_POSTS_FAILURE';

export const fetchFooterPostsRequest = createAction(FETCH_FOOTER_POSTS_REQUEST);
export const fetchFooterPostsSuccess = createAction(FETCH_FOOTER_POSTS_SUCCESS);
export const fetchFooterPostsFailure = createAction(FETCH_FOOTER_POSTS_FAILURE);

export function fetchFooterPosts({ categoryIdOrSlug, excludeSlug } = {}) {
  return (dispatch, getState, { request }) => {
    const state = getState();
    dispatch(fetchFooterPostsRequest(undefined, { categoryIdOrSlug }));

    const params = {
      offset: 0,
      size: excludeSlug ? 4 : 3,
      ...(categoryIdOrSlug ? { categoryId: categoryIdOrSlug } : {}),
      excludeTotalResults: true,
      useRichContent: true,
    };

    const promise = request(uri('/posts/feed/createdDate').query({ ...params }), {
      parseHeaders: true,
    });

    if (!isSSR(state)) {
      promise.then((response) => {
        const footerPosts = response.body.filter((p) => p && p.slug !== excludeSlug).slice(0, 3);
        if (footerPosts.length > 0) {
          dispatch(markPostsAsSeen(footerPosts.map((p) => p._id)));
          dispatch(fetchCounters({ postIds: footerPosts.map((p) => p._id) }));
        }
      });
    }

    return promise.then(
      ({ body = [] }) => {
        const footerPosts = body.filter((p) => p && p.slug !== excludeSlug).slice(0, 3);

        // @TODO remove mapBestAnswerToMarkedComments, when markedComents is implemented in back-end
        return dispatch(fetchFooterPostsSuccess(mapBestAnswerToMarkedComments(footerPosts)));
      },
      () => dispatch(fetchFooterPostsFailure()),
    );
  };
}
