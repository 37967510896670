import { isEmpty } from 'lodash';
import {
  FORUM_APP_ID,
  FORUM_SECTION_ID,
} from '@wix/communities-forum-universal/dist/src/constants/appsConfig.prod';
import { EXPERIMENT_USE_PRICING_PLANS_VIEWER_API } from '@wix/communities-forum-client-commons';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import createAction from '../../common/services/create-action';
import { getPaidPlansSectionUrl } from '../services/get-paid-plans-section-url';
import { isExperimentEnabled } from '../selectors/experiments-selectors';

export const NAVIGATE_TO_PAID_PLANS = 'app/NAVIGATE_TO_PAID_PLANS';

const navigateToPaidPlansAction = createAction(NAVIGATE_TO_PAID_PLANS);

export const navigateToPaidPlans =
  (returnUrl = '', planIds, titleText, buttonText, categoryId) =>
  (dispatch, getState, { wixCodeApi }) => {
    if (isExperimentEnabled(getState(), EXPERIMENT_USE_PRICING_PLANS_VIEWER_API)) {
      return getPricingPlansPublicAPI(wixCodeApi).then((api) =>
        api.navigateToPricingPage({
          planIds,
          biOptions: {
            referralInfo: 'forum',
            referralId: categoryId,
          },
          checkout: {
            thankYouPage: {
              content: {
                title: titleText,
                cta: buttonText,
              },
              navigation: {
                type: 'section',
                options: {
                  sectionId: FORUM_SECTION_ID,
                  appDefinitionId: FORUM_APP_ID,
                  state: returnUrl,
                  shouldRefreshIframe: true,
                },
              },
            },
          },
        }),
      );
    }

    const queryParams = {};
    if (returnUrl) {
      queryParams.navigateToSectionProps = btoa(
        JSON.stringify({
          sectionId: FORUM_SECTION_ID,
          appDefinitionId: FORUM_APP_ID,
          state: returnUrl,
          shouldRefreshIframe: true,
        }),
      );
    }
    if (planIds) {
      queryParams.planIds = planIds.join(',');
    }
    if (titleText || buttonText) {
      const content = {};
      if (titleText) {
        content.titleText = titleText;
      }
      if (buttonText) {
        content.buttonText = buttonText;
      }
      try {
        queryParams.verticalStatusContent = btoa(JSON.stringify(content)); // this throws with base-64 chars (japan language for example)
      } catch (error) {}
    }
    queryParams.biOptions = btoa(
      JSON.stringify({
        referralInfo: 'forum',
        referralId: categoryId,
      }),
    );
    let path = '';
    if (!isEmpty(queryParams)) {
      path = `?appSectionParams=${encodeURIComponent(JSON.stringify(queryParams))}`;
    }
    const sectionUrl = getPaidPlansSectionUrl(wixCodeApi);
    dispatch(navigateToPaidPlansAction({ path }));
    return wixCodeApi.location.to(`${sectionUrl}${path}`);
  };
