import { SET_IS_LOADING } from '../actions/set-is-loading';
import { FETCH_POST_REQUEST, FETCH_POST_SUCCESS, FETCH_POST_FAILURE } from '../actions/fetch-post';
import {
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
} from '../actions/fetch-posts';
import { FETCH_SEARCH_SUCCESS, FETCH_SEARCH_FAILURE } from '../actions/fetch-search';
import { SEARCH_CLEAR_RESULTS } from '../actions/search';
import {
  FETCH_POST_PAGE_DATA_REQUEST,
  FETCH_POST_PAGE_DATA_SUCCESS,
  FETCH_POST_PAGE_DATA_FAILURE,
} from '../actions/fetch-post-page-data';
import {
  FETCH_FOOTER_POSTS_REQUEST,
  FETCH_FOOTER_POSTS_SUCCESS,
  FETCH_FOOTER_POSTS_FAILURE,
} from '../actions/fetch-footer-posts';
import {
  FETCH_EXTERNAL_LINKS_REQUEST,
  FETCH_EXTERNAL_LINKS_SUCCESS,
  FETCH_EXTERNAL_LINKS_FAILURE,
} from '../actions/fetch-external-links';
import {
  FETCH_SIMILAR_POSTS_REQUEST,
  FETCH_SIMILAR_POSTS_SUCCESS,
  FETCH_SIMILAR_POSTS_FAILURE,
} from '../actions/fetch-similar-posts';
// eslint-disable-next-line complexity
export default function isLoading(state = {}, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return setIsLoading(state, action.payload);
    case FETCH_POST_REQUEST: {
      return setIsLoading(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoading: true,
      });
    }
    case FETCH_POST_PAGE_DATA_REQUEST: {
      return setIsLoading(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoading: true,
      });
    }
    case FETCH_POST_SUCCESS:
    case FETCH_POST_FAILURE:
      return setIsLoading(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoading: false,
      });
    case FETCH_POST_PAGE_DATA_FAILURE:
    case FETCH_POST_PAGE_DATA_SUCCESS: {
      return setIsLoading(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoading: false,
      });
    }
    case FETCH_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'posts',
        isLoading: true,
      });
    }
    case FETCH_POSTS_SUCCESS:
    case FETCH_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'posts',
        isLoading: false,
      });
    case FETCH_SEARCH_SUCCESS:
    case FETCH_SEARCH_FAILURE:
    case SEARCH_CLEAR_RESULTS:
      return setIsLoading(state, {
        entity: 'search',
        isLoading: false,
      });
    case FETCH_FOOTER_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'footer-posts',
        id: '',
        isLoading: true,
      });
    }
    case FETCH_FOOTER_POSTS_SUCCESS:
    case FETCH_FOOTER_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'footer-posts',
        id: '',
        isLoading: false,
      });
    case FETCH_SIMILAR_POSTS_REQUEST:
      return setIsLoading(state, {
        entity: 'similar-posts',
        id: '',
        isLoading: true,
      });
    case FETCH_SIMILAR_POSTS_SUCCESS:
    case FETCH_SIMILAR_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'similar-posts',
        id: '',
        isLoading: false,
      });
    case FETCH_EXTERNAL_LINKS_REQUEST:
      return setIsLoading(state, {
        entity: 'external-links',
        id: '',
        isLoading: true,
      });
    case FETCH_EXTERNAL_LINKS_SUCCESS:
    case FETCH_EXTERNAL_LINKS_FAILURE:
      return setIsLoading(state, {
        entity: 'external-links',
        id: '',
        isLoading: false,
      });
    default:
      return state;
  }
}

function setIsLoading(state, { entity, id = '', isLoading }) {
  return {
    ...state,
    [entity]: {
      ...state[entity],
      [id]: isLoading,
    },
  };
}
