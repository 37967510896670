export const ROUTE_404 = '/404';
export const ROUTE_SEARCH = '/search(/:query)';
export const ROUTE_LOGIN = '/login';
export const ROUTE_ACCOUNT_SUSPENDED = '/account-suspended';
export const ROUTE_CREATE_POST = '/create-post';
export const ROUTE_CREATE_QUESTION = '/create-question';
export const ROUTE_COMMENT_DEEP_LINK = '/main/comment/:commentId';
export const ROUTE_POST_DEEP_LINK = '/main/post/:postId';
export const ROUTE_CATEGORY = '/:categorySlug(/p-:page)';
export const ROUTE_CATEGORY_CREATE_POST = '/:categorySlug/create-post';
export const ROUTE_CATEGORY_CREATE_QUESTION = '/:categorySlug/create-question';
export const ROUTE_POST = '/:categorySlug/:postSlug(/p-:commentsPage)(/dl-:deepLinkData)';
export const ROUTE_POST_EDIT = '/:categorySlug/:postSlug/edit';
export const ROUTE_HOME = '/(p-:page)';

export const ROUTE_DEV_PLAYGROUND = '/dev-playground';

export const FOCUS_COMMENT_BOX_DEEP_LINK_ID = 'focus';

export const ALL_ROUTES = {
  [ROUTE_404]: ROUTE_404,
  [ROUTE_SEARCH]: ROUTE_SEARCH,
  [ROUTE_LOGIN]: ROUTE_LOGIN,
  [ROUTE_ACCOUNT_SUSPENDED]: ROUTE_ACCOUNT_SUSPENDED,
  [ROUTE_CREATE_POST]: ROUTE_CREATE_POST,
  [ROUTE_CREATE_QUESTION]: ROUTE_CREATE_QUESTION,
  [ROUTE_COMMENT_DEEP_LINK]: ROUTE_COMMENT_DEEP_LINK,
  [ROUTE_POST_DEEP_LINK]: ROUTE_POST_DEEP_LINK,
  [ROUTE_CATEGORY]: ROUTE_CATEGORY,
  [ROUTE_CATEGORY_CREATE_POST]: ROUTE_CATEGORY_CREATE_POST,
  [ROUTE_CATEGORY_CREATE_QUESTION]: ROUTE_CATEGORY_CREATE_QUESTION,
  [ROUTE_POST]: ROUTE_POST,
  [ROUTE_POST_EDIT]: ROUTE_POST_EDIT,
  [ROUTE_HOME]: ROUTE_HOME,
  [ROUTE_DEV_PLAYGROUND]: ROUTE_DEV_PLAYGROUND,
};
