import { SET_LOCATION } from './location-actions';
import { NAVIGATE_WITHIN_FORUM } from '../../actions/navigate-within-forum';

export function location(state = null, action) {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    case NAVIGATE_WITHIN_FORUM:
      return {
        ...state,
        prevPath: action.payload.prevPath,
      };
    default:
      return state;
  }
}
