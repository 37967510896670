import createAction from '../../../services/create-action';
import { addModalToRegistry, resolveModalInRegistry } from './opened-modals-promise-registry';

export const OPEN_MODAL = 'modal/OPEN';
export const CLOSE_MODAL = 'modal/CLOSE';
export const LOAD_MODAL_START = 'modal/LOAD_START';
export const LOAD_MODAL_STOP = 'modal/LOAD_STOP';

export const openModal = createAction(
  OPEN_MODAL,
  (type, props, meta, correlationId) => ({ type, props, correlationId }),
  (type, props, meta) => meta,
);
export const closeModal = createAction(CLOSE_MODAL, (payload) => payload);

export const loadModalStart = createAction(LOAD_MODAL_START, (type) => ({ type }));
export const loadModalStop = createAction(LOAD_MODAL_STOP, (type) => ({ type }));

// wrapper for component side
export const withPromisifiedOpenModal = (openModal) => (type, props, meta) => {
  const { correlationId, promise } = addModalToRegistry();
  openModal(type, props, meta, correlationId);
  return promise;
};

// wrapper for component side
export const withPromisifiedCloseModal = (closeModal) => (props) => {
  resolveModalInRegistry(props.correlationId, props.resolve);
  return closeModal(props);
};
