import { getFeedType } from './header-navigation.selectors';
import { FeedType } from '../models';
import { createFeedTypeQueryParam } from '../utils';
import { setSavedAction } from '../../../../common/store/saved-action/set-saved-action';
import { GO_TO_MY_POSTS } from '../../../constants/interactions';
import { requestLoginPromisified } from '../../../actions/request-login';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';
import { RootState } from '../../../types/store-types';
import { getCurrentUser } from '../../../../common/store/current-user/current-user-selectors';
import { resetCategoryFilter } from '../../../actions/reset-filter';
import { setPostsPageSorting } from '../../../actions/set-sorting';
import { SORT_BY_LAST_ACTIVITY } from '@wix/communities-forum-client-commons';
import { createAction } from '@reduxjs/toolkit';
import { startLoadingCategory } from '../../../actions/start-loading-category';
import { getRouteParams } from '../../../../common/router/router-selectors';
import { getPostSorting, getStyle } from '../../../selectors/app-settings-selectors';

const NAVIGATE_TO_MAIN_PAGE = 'headerNavigation/NAVIGATE_TO_MAIN_PAGE';

export const navigateToMainPageStart = createAction<FeedType>(NAVIGATE_TO_MAIN_PAGE);

export const navigateToMainPage =
  (id: FeedType, feedType: FeedType | null) =>
  (dispatch: (action: any) => void, getState: () => RootState) => {
    const state = getState();
    const isAuthenticated = !!getCurrentUser(state);
    const previousFeedType = getFeedType(state) ?? null;
    const { page } = getRouteParams(state);

    if (feedType === FeedType.MyPosts && !isAuthenticated) {
      dispatch(
        setSavedAction({
          action: GO_TO_MY_POSTS,
          args: `?${createFeedTypeQueryParam(FeedType.MyPosts)}`,
        }),
      );
      return dispatch(requestLoginPromisified(['login']));
    }
    if (id !== FeedType.Categories && (feedType !== previousFeedType || page)) {
      dispatch(startLoadingCategory(null));
    }

    dispatch(resetCategoryFilter());
    dispatch(
      setPostsPageSorting(getPostSorting(getStyle(state)) ?? SORT_BY_LAST_ACTIVITY, {
        dontReportBI: true,
      }),
    );
    dispatch(navigateToMainPageStart(id));
    dispatch(navigateWithinForum(`${feedType ? `?${createFeedTypeQueryParam(feedType)}` : ''}`));
  };
